/* eslint-disable @typescript-eslint/no-explicit-any */
import {
	Button,
	Col,
	Form,
	Input,
	InputNumber,
	Modal,
	Row,
	Select,
	Tooltip,
} from 'antd';
import TextArea from 'antd/lib/input/TextArea';
import React, { useEffect, useState } from 'react';
import clientListStyles from 'design/scss/clientList.module.scss';
import { WarningOutlined } from '@ant-design/icons';
import { mandateListById } from 'services/client';

interface Props {
	isAdvanceEditModalVisible: boolean;
	handleAdvanceEditCancel: () => void;
	selectedData: any;
	editForm: any;
	handleAdvanceEditFormSubmit: (values: any) => void;
}

const EditAdvanceAdmin: React.FC<Props> = ({
	isAdvanceEditModalVisible,
	handleAdvanceEditCancel,
	selectedData,
	editForm,
	handleAdvanceEditFormSubmit,
}) => {
	const [mandate, setMandate] = useState(null);

	useEffect(() => {
		getMandateData();
	}, [selectedData]);

	const getMandateData = async () => {
		const data = await mandateListById(selectedData?.clientId);
		setMandate(data);
	};
	const { Option } = Select;
	return (
		<Modal
			title={
				<Row align="middle">
					<h3 style={{ margin: '16px 16px 16px 0px' }}>Update Advance</h3>
				</Row>
			}
			visible={isAdvanceEditModalVisible}
			onCancel={handleAdvanceEditCancel}
			footer={null}
		>
			<div>
				<Col>
					<Row className={clientListStyles.Clientmodal_spanContainingRow}>
						<span>
							<b>ID:</b>
						</span>
						<span>{selectedData !== null && selectedData.clientId}</span>
					</Row>
				</Col>
				<Col>
					<Row className={clientListStyles.Clientmodal_spanContainingRow}>
						<span>
							<b>Advance ID:</b>
						</span>
						<span>{selectedData !== null && selectedData.advanceId}</span>
					</Row>
				</Col>
			</div>
			<br />
			<Form
				name="basic"
				layout="vertical"
				requiredMark={false}
				form={editForm}
				onFinish={handleAdvanceEditFormSubmit}
			>
				<div className={clientListStyles.transactionDetailsformContent}>
					<Row align="middle">
						<Col span={12}>
							<b>Change Status</b>
						</Col>
						<Col span={12}>
							<Row justify="start" style={{ width: '100%' }}>
								<Form.Item name="status" style={{ width: '100%', margin: 0 }}>
									<Select style={{ width: '100%', margin: '8px 0px' }}>
										<Option value="CREATED">CREATED</Option>
										<Option value="ACCEPTED">ACCEPTED</Option>
										<Option value="FUNDED">FUNDED</Option>
										<Option value="ACTIVE">ACTIVE</Option>
										<Option value="DECLINED">DECLINED</Option>
										<Option value="DELETED">DELETED</Option>
										<Option value="FULFILLED">FULFILLED</Option>
										<Option value="PAUSED">PAUSED</Option>
									</Select>
								</Form.Item>
							</Row>
						</Col>
					</Row>
					<Row align="middle">
						<Col span={12}>
							<b>Mandate</b>
						</Col>
						<Col span={12}>
							<Row justify="start" style={{ width: '100%' }}>
								<Form.Item
									name="goCardlessMandate"
									style={{ width: '100%', margin: 0 }}
								>
									<Select allowClear>
										{mandate?.map((item: any) => (
											<Option
												value={item.mandate}
											>{`${item.mandate}-${item.country}-${item.scheme}`}</Option>
										))}
									</Select>
								</Form.Item>
							</Row>
						</Col>
					</Row>
					<Row align="middle">
						<Col span={12}>
							<b>Repayment Frequency</b>
						</Col>
						<Col span={12}>
							<Row justify="start" style={{ width: '100%' }}>
								<Form.Item
									name="repaymentFrequency"
									style={{ width: '100%', margin: 0 }}
								>
									<Select style={{ width: '100%', margin: '8px 0px' }}>
										<Option value="DAILY">DAILY</Option>
										<Option value="WEEKLY">WEEKLY</Option>
									</Select>
								</Form.Item>
							</Row>
						</Col>
					</Row>
					<Row>
						<Col span={12}>
							<b>Advance Amount</b>
						</Col>
						<Col span={12}>
							<Form.Item style={{ margin: '0px' }}>
								<Input.Group compact>
									<Form.Item name="currency" noStyle>
										<Input
											style={{ width: '20%' }}
											disabled
											size="small"
											name="currency"
										/>
									</Form.Item>
									<Form.Item
										name="initialBalance"
										noStyle
										rules={[
											{
												required: true,
												message: 'Please input your requested advance amount',
											},
											{
												pattern: /^\d+(\.\d{1,2})?$/g,
												message: 'only 2 digits after decimal is accepted',
											},
										]}
									>
										<InputNumber
											style={{ width: '80%' }}
											placeholder="500,000"
											size="small"
											formatter={(value) =>
												`${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
											}
										/>
									</Form.Item>
								</Input.Group>
							</Form.Item>
						</Col>
					</Row>
					<Row>
						<Col span={12}>
							<b>Fee Amount</b>
						</Col>
						<Col span={12}>
							<Form.Item style={{ margin: '0px' }}>
								<Input.Group compact>
									<Form.Item name="currency" noStyle>
										<Input style={{ width: '20%' }} disabled size="small" />
									</Form.Item>
									<Form.Item
										name="fee"
										noStyle
										rules={[
											{
												required: true,
												message: 'Please input fee amount',
											},
											{
												pattern: /^\d+(\.\d{1,2})?$/g,
												message: 'only 2 digits after decimal is accepted',
											},
										]}
									>
										<InputNumber
											style={{ width: '80%' }}
											placeholder="500,000"
											size="small"
											formatter={(value) =>
												`${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
											}
										/>
									</Form.Item>
								</Input.Group>
							</Form.Item>
						</Col>
					</Row>
					<Row>
						<Col span={12}>
							<b>
								Repayment Rate{' '}
								<Tooltip title="Changing this field might affect the calculations!">
									<WarningOutlined style={{ color: 'red' }} />{' '}
								</Tooltip>
							</b>
						</Col>
						<Col span={12}>
							<Form.Item name="repaymentRate">
								<InputNumber
									style={{ width: '100%' }}
									min={0}
									max={100}
									formatter={(rate) => `${rate}%`}
									name="repaymentRate"
									type="float"
								/>
							</Form.Item>
						</Col>
					</Row>
					<Row>
						<Col span={12}>
							<b>
								Total Repaid{' '}
								<Tooltip title="Changing this field might affect the calculations!">
									<WarningOutlined style={{ color: 'red' }} />{' '}
								</Tooltip>
							</b>
						</Col>
						<Col span={12}>
							<Form.Item style={{ margin: '0px' }}>
								<Input.Group compact>
									<Form.Item name="currency" noStyle>
										<Input style={{ width: '20%' }} disabled size="small" />
									</Form.Item>
									<Form.Item
										name="totalRepaid"
										noStyle
										rules={[
											{
												required: true,
												message: 'Please enter the total repaid amount',
											},
											{
												pattern: /^\d+(\.\d{1,2})?$/g,
												message: 'only 2 digits after decimal is accepted',
											},
										]}
									>
										<InputNumber
											style={{ width: '80%' }}
											placeholder="500,000"
											size="small"
											formatter={(value) =>
												`${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
											}
										/>
									</Form.Item>
								</Input.Group>
							</Form.Item>
						</Col>
					</Row>
					<Row>
						<Col span={12}>
							<b>
								Amount Outstanding{' '}
								<Tooltip title="Changing this field might affect the calculations!">
									<WarningOutlined style={{ color: 'red' }} />
								</Tooltip>
							</b>
						</Col>
						<Col span={12}>
							<Form.Item style={{ margin: '0px' }}>
								<Input.Group compact>
									<Form.Item name="currency" noStyle>
										<Input style={{ width: '20%' }} disabled size="small" />
									</Form.Item>
									<Form.Item
										name="totalAmountOutstanding"
										noStyle
										rules={[
											{
												required: true,
												message: 'Please enter the total amount outstanding',
											},
											{
												pattern: /^\d+(\.\d{1,2})?$/g,
												message: 'only 2 digits after decimal is accepted',
											},
										]}
									>
										<InputNumber
											style={{ width: '80%' }}
											placeholder="500,000"
											size="small"
											formatter={(value) =>
												`${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
											}
										/>
									</Form.Item>
								</Input.Group>
							</Form.Item>
						</Col>
					</Row>
					<Row>
						<Col span={12}>
							<b>
								Total Repaid Projection{' '}
								<Tooltip title="Changing this field might affect the calculations!">
									<WarningOutlined style={{ color: 'red' }} />{' '}
								</Tooltip>
							</b>
						</Col>
						<Col span={12}>
							<Form.Item style={{ margin: '0px' }}>
								<Input.Group compact>
									<Form.Item name="currency" noStyle>
										<Input style={{ width: '20%' }} disabled size="small" />
									</Form.Item>
									<Form.Item
										name="totalRepaidProjection"
										noStyle
										rules={[
											{
												required: true,
												message: 'Please enter the total repaid projection',
											},
											{
												pattern: /^\d+(\.\d{1,2})?$/g,
												message: 'only 2 digits after decimal is accepted',
											},
										]}
									>
										<InputNumber
											style={{ width: '80%' }}
											placeholder="500,000"
											size="small"
											formatter={(value) =>
												`${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
											}
										/>
									</Form.Item>
								</Input.Group>
							</Form.Item>
						</Col>
					</Row>
					<Row>
						<Col span={12}>
							<b>
								Minimum Repayment{' '}
								<Tooltip title="Changing this field might affect the calculations!">
									<WarningOutlined style={{ color: 'red' }} />{' '}
								</Tooltip>
							</b>
						</Col>
						<Col span={12}>
							<Form.Item style={{ margin: '0px' }}>
								<Input.Group compact>
									<Form.Item name="currency" noStyle>
										<Input style={{ width: '20%' }} disabled size="small" />
									</Form.Item>
									<Form.Item
										name="minRepayment"
										noStyle
										rules={[
											{
												pattern: /^\d+(\.\d{1,2})?$/g,
												message: 'only 2 digits after decimal is accepted',
											},
										]}
									>
										<InputNumber
											style={{ width: '80%' }}
											placeholder="500,000"
											size="small"
											min={0}
											formatter={(value) =>
												`${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
											}
										/>
									</Form.Item>
								</Input.Group>
							</Form.Item>
						</Col>
					</Row>
					<Row>
						<Col span={12}>
							<b>
								Maximum Repayment{' '}
								<Tooltip title="Changing this field might affect the calculations!">
									<WarningOutlined style={{ color: 'red' }} />{' '}
								</Tooltip>
							</b>
						</Col>
						<Col span={12}>
							<Form.Item style={{ margin: '0px' }}>
								<Input.Group compact>
									<Form.Item name="currency" noStyle>
										<Input style={{ width: '20%' }} disabled size="small" />
									</Form.Item>
									<Form.Item
										name="maxRepayment"
										noStyle
										rules={[
											{
												pattern: /^\d+(\.\d{1,2})?$/g,
												message: 'only 2 digits after decimal is accepted',
											},
										]}
									>
										<InputNumber
											style={{ width: '80%' }}
											placeholder="500,000"
											size="small"
											min={0}
											formatter={(value) =>
												`${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
											}
										/>
									</Form.Item>
								</Input.Group>
							</Form.Item>
						</Col>
					</Row>
					<Row>
						<Col span={24}>Notes</Col>
						<Col span={24}>
							<Form.Item name="note">
								<TextArea name="note" rows={4} showCount maxLength={1000} />
							</Form.Item>
						</Col>
					</Row>
					<br />
					<Row justify="center">
						<Button className="_form_submit_small" type="primary" htmlType="submit">
							Confirm
						</Button>
					</Row>
				</div>
			</Form>
		</Modal>
	);
};

export default EditAdvanceAdmin;
