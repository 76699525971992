/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';
import { Menu } from 'antd';
import navStyles from '../../design/scss/navbarComponents.module.scss';
import Router from 'next/router';
import {
	LayoutOutlined,
	UsergroupAddOutlined,
	RiseOutlined,
	BarChartOutlined,
	CameraOutlined,
	CreditCardOutlined,
	NotificationOutlined,
	SafetyOutlined,
	WechatOutlined,
	InfoCircleOutlined,
	DeploymentUnitOutlined,
	SettingOutlined,
	BorderOutlined,
	UserOutlined,
	DatabaseOutlined,
	OrderedListOutlined,
	BankOutlined,
	MoneyCollectOutlined,
	UserAddOutlined,
	UsergroupDeleteOutlined,
} from '@ant-design/icons';
import { connect } from 'react-redux';
import AdminControllerPermission from 'dto/permissions/admin.permission';
import { Role } from 'dto/admin.client.dto';

interface MainMenuProps {
	screen: string;
	level: string;
	role: Role;
}

//submenu keys
const rootSubmenuKeys = [
	'dashboard',
	'client',
	'business_operations',
	'marketing',
	'support',
	'business',
	'settings',
];

const { SubMenu } = Menu;
const MainMenu: React.FC<MainMenuProps> = ({ screen, level, role }) => {
	const [openKeys, setOpenKeys] = React.useState(['client']);

	const onOpenChange = (keys: any) => {
		const latestOpenKey = keys.find((key: any) => openKeys.indexOf(key) === -1);
		if (rootSubmenuKeys.indexOf(latestOpenKey) === -1) {
			setOpenKeys(keys);
		} else {
			setOpenKeys(latestOpenKey ? [latestOpenKey] : []);
		}
	};

	return (
		<React.Fragment>
			<Menu
				mode="inline"
				openKeys={openKeys}
				onOpenChange={onOpenChange}
				theme={'light'}
				className={screen !== 'mobile' ? navStyles.navbar_menu : ''}
			>
				<Menu.Item key="dashboard" icon={<LayoutOutlined />}>
					Dashboard
				</Menu.Item>
				<Menu.Item
					key="client"
					icon={<UsergroupAddOutlined />}
					onClick={() => {
						Router.push('/dashboard/clients');
					}}
				>
					Clients
				</Menu.Item>
				<SubMenu
					key="business_operations"
					icon={<BarChartOutlined />}
					title="Business Operations"
				>
					<Menu.Item key="business_operations_Analytics" icon={<BarChartOutlined />}>
						Analytics
					</Menu.Item>
					<Menu.Item
						key="business_operations_Advances"
						icon={<CameraOutlined />}
						onClick={() => {
							Router.push('/dashboard/advances');
						}}
					>
						Advances
					</Menu.Item>
					<SubMenu key="repayments" icon={<CreditCardOutlined />} title="Repayments">
						<Menu.Item
							key="gocardless_Repayment"
							icon={<MoneyCollectOutlined />}
							onClick={() => {
								Router.push('/dashboard/repayments');
							}}
						>
							GoCardLess
						</Menu.Item>
						<Menu.Item
							key="manual_bank_Repayment"
							icon={<BankOutlined />}
							onClick={() => {
								Router.push('/dashboard/manualRepayment');
							}}
						>
							Manual Bank Transfer
						</Menu.Item>
					</SubMenu>
					<Menu.Item
						key="business_operations_CSVList"
						icon={<OrderedListOutlined />}
						onClick={() => {
							Router.push('/dashboard/csvList');
						}}
					>
						CSV Reports
					</Menu.Item>
				</SubMenu>
				<SubMenu key="marketing" icon={<NotificationOutlined />} title="Marketing">
					<Menu.Item
						key="Leads"
						icon={<RiseOutlined />}
						onClick={() => {
							Router.push('/dashboard/leadList');
						}}
					>
						Leads
					</Menu.Item>
					<Menu.Item key="Traffic_Analytics" icon={<BarChartOutlined />}>
						Traffic Analytics
					</Menu.Item>
				</SubMenu>
				<SubMenu key="support" icon={<SafetyOutlined />} title="Support">
					<Menu.Item key="Customer_chat" icon={<WechatOutlined />}>
						Customer Chat
					</Menu.Item>
					<Menu.Item key="Issue_tracker" icon={<InfoCircleOutlined />}>
						Issue Tracker
					</Menu.Item>
				</SubMenu>
				<Menu.Item key="business" icon={<DeploymentUnitOutlined />}>
					Business Intelligence Platform
				</Menu.Item>
				<SubMenu key="settings" icon={<SettingOutlined />} title="Settings">
					<Menu.Item key="System_settings" icon={<BorderOutlined />}>
						System Settings
					</Menu.Item>
					<Menu.Item key="Account_settings" icon={<UserOutlined />}>
						Account Settings
					</Menu.Item>
				</SubMenu>
				<Menu.Item
					key="logs"
					icon={<DatabaseOutlined />}
					onClick={() => {
						Router.push('/dashboard/logsList');
					}}
				>
					Logs
				</Menu.Item>{' '}
				{(role?.permissions?.includes(AdminControllerPermission.ReadAdminAccount) ||
					level === AdminControllerPermission.SUPER_ADMIN) && (
					<Menu.Item
						key="Users"
						icon={<UserAddOutlined />}
						onClick={() => {
							Router.push('/dashboard/usersList');
						}}
					>
						Users
					</Menu.Item>
				)}
				{level === AdminControllerPermission.SUPER_ADMIN && (
					<Menu.Item
						key="Roles"
						icon={<UsergroupDeleteOutlined />}
						onClick={() => {
							Router.push('/dashboard/roleList');
						}}
					>
						Roles
					</Menu.Item>
				)}
			</Menu>
		</React.Fragment>
	);
};

const mapStateToProps = (state: any) => ({
	level: state.auth.loggedInUser?.level,
	role: state.auth.loggedInUser?.role,
});

export default connect(mapStateToProps)(MainMenu);
