import Head from 'next/head';

const NotFoundLayout: React.FC = () => {
	return (
		<div className="">
			<Head>
				<title>Valerian | 404</title>
				<link rel="shortcut icon" href="/favicon.ico" />
				<link rel="icon" sizes="192x192" href="/logo192.png" />
			</Head>

			<main className="_middle my-5">
				<img src={require('../public/images/404.svg')} alt="Not Found" />
			</main>

			<footer className=""></footer>
		</div>
	);
};

export default NotFoundLayout;
