import React, { useState } from 'react';
import {
	Row,
	Col,
	Card,
	Space,
	Input,
	DatePicker,
	Button,
	Modal,
	Form,
	Radio,
	InputNumber,
	Select,
} from 'antd';
import RepaymentsTable from '../../pages/dashboard/TableComponent/RepaymentTable';
import { CreditCardOutlined, EditOutlined } from '@ant-design/icons';
import clientListStyles from '../../design/scss/clientList.module.scss';

const { Search } = Input;
const { TextArea } = Input;
const { Option } = Select;

const RepaymentsCard: React.FC = () => {
	const [value, setValue] = React.useState('Connections');

	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	const onChange = (e: any) => {
		setValue(e.target.value);
	};

	const [isRepaymentEditModalVisible] = useState(false);
	const [isTransactionDetailsModalVisible, setIsTransactionDetailsModalVisible] =
		useState(false);
	const showTransactionModal = () => {
		setIsTransactionDetailsModalVisible(true);
	};
	const handleTransactionCancel = () => {
		setIsTransactionDetailsModalVisible(false);
	};
	const handleTransactionFormEdit = () => {
		setIsTransactionDetailsModalVisible(false);
		showTransactionEditModal();
	};
	const [isTransactionEditModalVisible, setIsTransactionEditModalVisible] =
		useState(false);
	const showTransactionEditModal = () => {
		setIsTransactionEditModalVisible(true);
	};
	const handleTransactionEditCancel = () => {
		setIsTransactionEditModalVisible(false);
	};
	const handleTransactionEditFormSubmit = () => {
		setIsTransactionEditModalVisible(false);
	};

	const columns = [
		{
			title: 'Updated On',
			dataIndex: 'updated_on',
			key: 'updated_on',
			render: (text: string) => (
				<div style={{ display: 'flex', flexDirection: 'column' }}>
					<span>{text.slice(0, 12)}</span>
					<span style={{ fontSize: '10px' }}>{text.slice(13, 24)}</span>
				</div>
			),
		},
		{
			title: 'ID',
			dataIndex: 'id',
			key: 'id',
			render: (text: string) => (
				<div style={{ display: 'flex', flexDirection: 'column' }}>
					<span>{text.slice(0, 8)}</span>
					<span style={{ fontSize: '10px' }}>
						<b>{text.slice(9, 17)}</b> {text.slice(17, 25)}
					</span>
				</div>
			),
		},
		{
			title: 'Status',
			dataIndex: 'status',
			key: 'status',
		},
		{
			title: 'Source',
			dataIndex: 'source',
			key: 'source',
		},
		{
			title: 'Type',
			dataIndex: 'type',
			key: 'type',
		},
		{
			title: 'Repayment Value',
			dataIndex: 'repayment_value',
			key: 'repayment_value',
		},
		{
			title: 'Repayment Rate',
			dataIndex: 'repayment_rate',
			key: 'repayment_rate',
		},
		{
			title: 'Total Revenue',
			dataIndex: 'total_revenue',
			key: 'total_revenue',
		},
		{
			title: 'Action',
			key: 'action',
			render: () => (
				<Space size="middle">
					<a onClick={() => showTransactionModal()}>{'Details>'}</a>
				</Space>
			),
		},
	];

	const data = [];
	for (let i = 0; i < 100; i++) {
		data.push({
			key: i,
			updated_on: `Mon 12/04/21 12:34:56 PM${i}`,
			id: 'TRX-1138 Adv. ID: AD-2323',
			status: 'Scheduled',
			source: 'Stripe',
			type: 'System',
			repayment_value: '€1,000.00',
			repayment_rate: '13%',
			total_revenue: '€25,938.02',
		});
	}
	return (
		<>
			<Modal
				title={
					<Row align="middle">
						<h3 style={{ margin: '16px 16px 16px 0px' }}>Edit Transaction</h3>
					</Row>
				}
				visible={isTransactionEditModalVisible}
				onCancel={handleTransactionEditCancel}
				footer={null}
			>
				<div>
					<Col>
						<h4 style={{ margin: 0 }}>{'TRX-1138'}</h4>
					</Col>
					<Col>
						<Row className={clientListStyles.Clientmodal_spanContainingRow}>
							<span>
								<b>ID:</b>
							</span>
							<span> Client-123456</span>
						</Row>
					</Col>
					<Col>
						<Row className={clientListStyles.Clientmodal_spanContainingRow}>
							<span>
								<b>Advance ID:</b>
							</span>
							<span> AD-2323</span>
						</Row>
					</Col>
				</div>
				<br />
				<Form
					name="basic"
					layout="vertical"
					requiredMark={false}
					onFinish={handleTransactionEditFormSubmit}
				>
					<div className={clientListStyles.transactionDetailsformContent}>
						<Row align="middle">
							<Col span={10}>
								<b>Currency</b>
							</Col>
							<Col span={6}>€ (EUR)</Col>
						</Row>
						<Row align="middle">
							<Col span={10}>
								<b>Change Status</b>
							</Col>
							<Col span={13}>
								<Row justify="start">
									<Select
										defaultValue="select"
										style={{ width: '100%', margin: '8px 0px' }}
									>
										<Option value="1">option 1</Option>
										<Option value="2">option 2</Option>
										<Option value="3">option 3</Option>
									</Select>
								</Row>
							</Col>
						</Row>
						<Row align="middle">
							<Col span={10}>
								<b>Source</b>
							</Col>
							<Col span={6}>
								<div className={clientListStyles.sourceButton}>Stripe</div>
							</Col>
						</Row>
						<Row align="middle">
							<Col span={10}>
								<b>Type</b>
							</Col>
							<Col span={6}>
								<Button className={clientListStyles.ActiveButton}>System</Button>
							</Col>
						</Row>
						<Row>
							<Col span={10}>
								<b>Repayment Rate</b>
							</Col>
							<Col span={6}>13%</Col>
						</Row>
						<Row>
							<Col span={10}>
								<b>Repayment Value</b>
							</Col>
							<Col span={6}>€1,000.00</Col>
						</Row>
						<Row>
							<Col span={10}>
								<b>Total Revenue</b>
							</Col>
							<Col span={6}>€4,212,959.42</Col>
						</Row>
						<Row>
							<Col span={10}>
								<b>Total Discount</b>
							</Col>
							<Col span={13}>
								<Form.Item style={{ margin: '0px' }}>
									<Input.Group compact>
										<Form.Item name="currency" noStyle>
											<Input
												style={{ width: '20%' }}
												name="currency"
												disabled
												size="small"
											/>
										</Form.Item>
										<Form.Item
											name="amount"
											noStyle
											rules={[
												{
													// required: true,
													message: 'Please input your requested advance amount',
												},
												{
													pattern: /\d+/g,
													message: 'Advance amount can be only digits',
												},
											]}
										>
											<InputNumber
												style={{ width: '80%' }}
												name="amount"
												placeholder="500,000"
												size="small"
												formatter={(value: number) =>
													`${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
												}
											/>
										</Form.Item>
									</Input.Group>
								</Form.Item>
							</Col>
						</Row>
						<Row>
							<Col span={10}>
								<b>Total Sales</b>
							</Col>
							<Col span={13}>
								<Form.Item style={{ margin: '0px' }}>
									<Input.Group compact>
										<Form.Item name="currency" noStyle>
											<Input
												style={{ width: '20%' }}
												name="currency"
												disabled
												size="small"
											/>
										</Form.Item>
										<Form.Item
											name="amount"
											noStyle
											rules={[
												{
													// required: true,
													message: 'Please input your requested advance amount',
												},
												{
													pattern: /\d+/g,
													message: 'Advance amount can be only digits',
												},
											]}
										>
											<InputNumber
												style={{ width: '80%' }}
												name="amount"
												placeholder="500,000"
												size="small"
												formatter={(value: number) =>
													`${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
												}
											/>
										</Form.Item>
									</Input.Group>
								</Form.Item>
							</Col>
						</Row>
						<Row>
							<Col span={10}>
								<b>Total Refunds</b>
							</Col>
							<Col span={13}>
								<Form.Item style={{ margin: '0px' }}>
									<Input.Group compact>
										<Form.Item name="currency" noStyle>
											<Input
												style={{ width: '20%' }}
												name="currency"
												disabled
												size="small"
											/>
										</Form.Item>
										<Form.Item
											name="amount"
											noStyle
											rules={[
												{
													message: 'Please input your requested advance amount',
												},
												{
													pattern: /\d+/g,
													message: 'Advance amount can be only digits',
												},
											]}
										>
											<InputNumber
												style={{ width: '80%' }}
												name="amount"
												placeholder="500,000"
												size="small"
												formatter={(value: number) =>
													`${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
												}
											/>
										</Form.Item>
									</Input.Group>
								</Form.Item>
							</Col>
						</Row>
						<Row>
							<Col span={10}>
								<b>Created At</b>
							</Col>
							<Col span={10}>March 26, 2021 • 02:03:04 AM</Col>
						</Row>
						<Row>
							<Col span={10}>
								<b>Updated At</b>
							</Col>
							<Col span={10}>March 26, 2021 • 02:03:04 AM</Col>
						</Row>
						<Row>
							<Col span={24}>Notes</Col>
							<Col span={24}>
								<TextArea
									name="transactionEditnotes"
									rows={4}
									showCount
									maxLength={1000}
								/>
							</Col>
						</Row>
						<br />
						<Row justify="center">
							<Button className="_form_submit_small" type="primary" htmlType="submit">
								Confirm
							</Button>
						</Row>
					</div>
				</Form>
			</Modal>
			<Modal
				title={
					<Row align="middle">
						<h3 style={{ margin: '16px 16px 16px 0px' }}>Transaction Details</h3>
					</Row>
				}
				visible={isTransactionDetailsModalVisible}
				onCancel={handleTransactionCancel}
				footer={null}
			>
				<Form
					name="basic"
					layout="vertical"
					requiredMark={false}
					onFinish={handleTransactionFormEdit}
				>
					<div className={clientListStyles.transactionDetailsformContent}>
						<Row>
							<Col span={10}>
								<b>ID</b>
							</Col>
							<Col span={6}>TRX-1138</Col>
						</Row>
						<Row>
							<Col span={10}>
								<b>Advance ID</b>
							</Col>
							<Col span={6}>AD-2323</Col>
						</Row>
						<Row>
							<Col span={10}>
								<b>Currency</b>
							</Col>
							<Col span={6}>€ (EUR)</Col>
						</Row>
						<Row align="middle">
							<Col span={10}>
								<b>Status</b>
							</Col>
							<Col span={11}>
								<div className={clientListStyles.statusButton}>
									Scheduled For Direct Debit
								</div>
							</Col>
						</Row>
						<Row align="middle">
							<Col span={10}>
								<b>Source</b>
							</Col>
							<Col span={6}>
								<div className={clientListStyles.sourceButton}>Stripe</div>
							</Col>
						</Row>
						<Row align="middle">
							<Col span={10}>
								<b>Type</b>
							</Col>
							<Col span={6}>
								<Button className={clientListStyles.ActiveButton}>System</Button>
							</Col>
						</Row>
						<Row>
							<Col span={10}>
								<b>Total Sale</b>
							</Col>
							<Col span={6}>€4,212,959.42</Col>
						</Row>
						<Row>
							<Col span={10}>
								<b>Total Discount</b>
							</Col>
							<Col span={6}>€12,959.42</Col>
						</Row>
						<Row>
							<Col span={10}>
								<b>Total Refunds</b>
							</Col>
							<Col span={6}>€2,959.42</Col>
						</Row>
						<Row>
							<Col span={10}>
								<b>Repayment Rate</b>
							</Col>
							<Col span={6}>13%</Col>
						</Row>
						<Row>
							<Col span={10}>
								<b>Repayment Value</b>
							</Col>
							<Col span={6}>€1,000.00</Col>
						</Row>
						<Row>
							<Col span={10}>
								<b>Repayment Revenue</b>
							</Col>
							<Col span={6}>€4,212,959.42</Col>
						</Row>
						<Row>
							<Col span={10}>
								<b>Created At</b>
							</Col>
							<Col span={10}>March 26, 2021 • 02:03:04 AM</Col>
						</Row>
						<Row>
							<Col span={10}>
								<b>Updated At</b>
							</Col>
							<Col span={10}>March 26, 2021 • 02:03:04 AM</Col>
						</Row>
						<Row>
							<Col span={10}>
								<b>Sent To GoCardless</b>
							</Col>
							<Col span={10}>March 26, 2021 • 02:03:04 AM</Col>
						</Row>
						<Row>
							<Col span={10}>
								<b>Charged At</b>
							</Col>
							<Col span={10}>March 26, 2021 • 02:03:04 AM</Col>
						</Row>
						<Row>
							<Col span={10}>
								<b>Paid Out At</b>
							</Col>
							<Col span={10}>March 26, 2021 • 02:03:04 AM</Col>
						</Row>
						<br />
						<Row justify="start">
							<Button
								className="adminBtnStyle"
								icon={<CreditCardOutlined />}
								type="primary"
								htmlType="submit"
							>
								Send Payment Request
							</Button>
							<Button
								className="adminRepaymentEditBtnStyle"
								icon={<EditOutlined />}
								type="default"
								htmlType="submit"
							>
								Edit Transaction
							</Button>
						</Row>
					</div>
				</Form>
			</Modal>
			<Modal
				title={
					<Row align="middle">
						<h3 style={{ margin: '16px 16px 16px 0px' }}>Add Manual Repayment</h3>
					</Row>
				}
				visible={isRepaymentEditModalVisible}
				footer={null}
			>
				<div>
					<Col>
						<h4 style={{ margin: 0 }}>{'Online Co.'}</h4>
					</Col>
					<Col>
						<Row className={clientListStyles.Clientmodal_spanContainingRow}>
							<span>
								<b>ID:</b>
							</span>
							<span> Client-123456</span>
						</Row>
					</Col>
				</div>
				<br />
				<Form name="basic" layout="vertical" requiredMark={false}>
					<Form.Item label="Calculate Form" name="calculate_form">
						<Radio.Group onChange={onChange} value={value}>
							<Radio value={'Connections'}>Connections</Radio>
							<Radio value={'Manual Data'}>Manual Data</Radio>
						</Radio.Group>
					</Form.Item>
					{value === 'Manual Data' ? (
						<Form.Item label="Amount ">
							<Input.Group compact>
								<Form.Item name="currency" noStyle>
									<Input style={{ width: '20%' }} name="currency" disabled />
								</Form.Item>
								<Form.Item
									name="amount"
									noStyle
									rules={[
										{
											message: 'Please input your requested advance amount',
										},
										{
											pattern: /\d+/g,
											message: 'Advance amount can be only digits',
										},
									]}
								>
									<InputNumber
										style={{ width: '80%' }}
										name="amount"
										placeholder="500,000"
										formatter={(value: number) =>
											`${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
										}
									/>
								</Form.Item>
							</Input.Group>
						</Form.Item>
					) : (
						''
					)}
					<Form.Item label="Source" name="source">
						<Input name="source" placeholder="Source" />
					</Form.Item>
					<Row justify="space-between">
						<Form.Item label="Start Date" name="sdate">
							<DatePicker onChange={onChange} style={{ width: '220px' }} />
						</Form.Item>
						<Form.Item label="End Date" name="edate">
							<DatePicker onChange={onChange} style={{ width: '220px' }} />
						</Form.Item>
					</Row>
					<Form.Item label="Notes" name="notes">
						<TextArea name="notes" rows={4} showCount maxLength={1000} />
					</Form.Item>
					<Row justify="center">
						{value === 'Manual Data' ? (
							<Button className="_form_submit_small" type="primary" htmlType="submit">
								Add Repayment
							</Button>
						) : (
							<Button className="_form_submit_small" type="primary" htmlType="submit">
								Calculate
							</Button>
						)}
					</Row>
				</Form>
			</Modal>

			<Card bordered={false}>
				<Row justify="space-between" align="middle">
					<h3>Repayments</h3>
					<Row className={clientListStyles.inputContainer}>
						<DatePicker.RangePicker className={clientListStyles.tableInputs} />
						<Search
							placeholder="search by name/email..."
							allowClear
							enterButton="Search"
							size="large"
							className={clientListStyles.tableInputs}
						/>
					</Row>
				</Row>
				<RepaymentsTable columns={columns} data={data} pagination={5} />

				<Row className={clientListStyles.repamentsButtonContainer}>
					<Button className="adminBtnStyle">Download Historical Data</Button>
				</Row>
			</Card>
		</>
	);
};

export default RepaymentsCard;
