/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useEffect, useState } from 'react';
import {
	Row,
	Col,
	Card,
	Space,
	DatePicker,
	Button,
	Modal,
	Form,
	Input,
	InputNumber,
	message,
	Divider,
	Select,
	Checkbox,
} from 'antd';

import { EditOutlined } from '@ant-design/icons';
import OfferStyle from '../../design/scss/offer.module.scss';

import getSymbolFromCurrency from 'currency-symbol-map';
import { useForm } from 'antd/lib/form/Form';
import { createClientOffer, updateClientOffer } from 'services/oldservice';
import {
	setClientOfferData,
	updateClientOfferData,
} from 'store/actions/dashboardActions';
import { logOut } from 'store/actions/authActions';
import { connect } from 'react-redux';
import clientListStyles from '../../design/scss/clientList.module.scss';
import { RootState } from 'dto/redux.action.dto';
import moment from 'moment-timezone';
import { DateRangePicker, ListViewTable } from '@valerian/core';
import qs from 'qs';
import { ClientInfo } from 'components/ClientInfoProps/clientInfo';
import { Role } from 'dto/admin.client.dto';
import OfferControllerPermission from 'dto/permissions/offer.permission';
import AdminControllerPermission from 'dto/permissions/admin.permission';
moment.tz.setDefault('Europe/London');
import { getRevenueSourceInfo, mandateList } from 'services/client';

import { currencyList } from './currencyList';
import OfferUpdateModal from 'components/OfferModal/OfferUpdateModal';
import { checkPermission } from 'utils/extra';
import ConnectControllerPermission from 'dto/permissions/connections.permissions';

const { TextArea } = Input;

interface Props {
	clientId: string;
	companyName: string;
	currency: string;
	currentPage: number;
	totalData: number;
	data: any[];
	loading: boolean;
	setOfferData: (query: string, id: string) => void;
	updateOfferData: (key: string, value: any) => void;
	clientInfo: ClientInfo;
	role: Role;
	level: string;
}

const OffersCard: React.FC<Props> = ({
	clientId,
	companyName,
	currency,
	currentPage,
	totalData,
	data,
	loading,
	setOfferData,
	updateOfferData,
	clientInfo,
	role,
	level,
}) => {
	const [isOfferModalVisible, setIsOfferModalVisible] = useState(false);
	const [offerEditModal, setOfferEditModal] = useState(false);
	const [page, setPage] = useState<number>(currentPage);
	const [fromDate, setFromDate] = useState<string | undefined>(undefined);
	const [toDate, setToDate] = useState<string | undefined>(undefined);
	const [mandate, setMandate] = useState(null);

	const [form] = useForm();
	const [editForm] = useForm();

	const queryString = qs.stringify({
		page,
		limit: 5,
		fromDate,
		toDate,
	});

	useEffect(() => {
		setOfferData(`${queryString}`, clientId);
	}, [totalData, page, fromDate, toDate]);

	useEffect(() => {
		setOfferData(`${queryString}`, clientId);
		getMandateData();
		form.setFieldsValue({
			repaymentGateway: 'GoCardless_LU',
		});
	}, [clientInfo]);

	const getMandateData = async () => {
		if (
			checkPermission(ConnectControllerPermission.ClientMandateInfo, role, level)
		) {
			const data = await mandateList(clientId);
			setMandate(data);
		}
	};

	const refreshData = () => {
		const queryString = qs.stringify({
			page,
			limit: 5,
			fromDate,
			toDate,
		});
		setOfferData(`${queryString}`, clientId);
	};

	const showModal = () => {
		setIsOfferModalVisible(true);
	};

	const handleCancel = () => {
		setIsOfferModalVisible(false);
		form.resetFields();
	};

	const handleFormSubmit = (values: {
		name: string;
		description: string;
		currency: string;
		amount: string;
		repayment_rate: number;
		fee: number;
		validity: moment.MomentInput;
		repaymentGateway: string;
		sources?: any[];
		repaymentFrequency: string;
		maxRepayment: number;
		minRepayment: number;
		sendEmailToClient: boolean;
		holidayPeriod: number;
		pandaDocClientLink: string;
		pandaDocValerianLink: string;
		goCardlessMandate: string;
		expectedRevenue: number;
		expectedRepayment: number;
		expectedROCE: number;
		expectedIRR: number;
	}) => {
		const newSources =
			values.sources && values.sources.map((source: any) => JSON.parse(source));
		const data = {
			clientId: clientId,
			offer: {
				name: values.name,
				description: values.description,
				currency: values.currency,
				amount: parseFloat(values.amount),
				repaymentRate: values.repayment_rate,
				fee: values.fee,
				validDate: moment(values.validity).format('YYYY-MM-DD'),
				repaymentGateway: values.repaymentGateway,
				repaymentFrequency: values.repaymentFrequency,
				holidayPeriod: values.holidayPeriod,
				minRepayment: values.minRepayment,
				maxRepayment: values.maxRepayment,
				pandaDocValerianLink: values.pandaDocValerianLink,
				pandaDocClientLink: values.pandaDocClientLink,
				goCardlessMandate: values.goCardlessMandate,
				expectedROCE: values?.expectedROCE / 100,
				expectedIRR: values?.expectedIRR / 100,
				expectedRepayment: values?.expectedRepayment,
				expectedRevenue: values?.expectedRevenue,
			},
			sources: newSources ? newSources : [],
			sendEmailToClient: values.sendEmailToClient,
		};
		createClientOffer(data)
			.then(() => {
				message.success('Offer Added');
				setIsOfferModalVisible(false);
				form.resetFields();
				refreshData();
			})
			.catch((err: any) => {
				if (err?.response?.status === 401) {
					logOut();
				}
				message.error(err.response.data.message);
			});
	};

	const handleEditFormSubmit = (values: {
		name: string;
		description: string;
		currency: string;
		amount: string;
		repaymentRate: number;
		fee: number;
		validity: moment.MomentInput;
		repaymentGateway: string;
		sources?: any[];
		repaymentFrequency: string;
		maxRepayment: number;
		minRepayment: number;
		sendEmailToClient: boolean;
		holidayPeriod: number;
		pandaDocClientLink: string;
		pandaDocValerianLink: string;
		expectedIRR: number;
		expectedROCE: number;
		expectedRevenue: number;
		expectedRepayment: number;
	}) => {
		const newSources =
			values.sources && values.sources.map((source: any) => JSON.parse(source));
		const data = {
			offerId: offerDetails.offerId,
			clientId: clientId,
			name: values.name,
			description: values.description,
			currency: values.currency,
			amount: parseFloat(values.amount),
			repaymentRate: values.repaymentRate,
			fee: values.fee,
			validDate: moment(values.validity).format('YYYY-MM-DD'),
			repaymentGateway: values.repaymentGateway,
			repaymentFrequency: values.repaymentFrequency,
			holidayPeriod: values.holidayPeriod,
			minRepayment: values.minRepayment,
			maxRepayment: values.maxRepayment,
			pandaDocValerianLink: values.pandaDocValerianLink,
			pandaDocClientLink: values.pandaDocClientLink,
			sources: newSources ? newSources : [],
			sendEmailToClient: values.sendEmailToClient,
			expectedRepayment: values?.expectedRepayment,
			expectedRevenue: values?.expectedRevenue,
			expectedIRR: values?.expectedIRR ? values?.expectedIRR / 100 : null,
			expectedROCE: values?.expectedROCE ? values?.expectedROCE / 100 : null,
		};
		updateClientOffer(data)
			.then(() => {
				refreshData();
				message.success('Offer Updated');
			})
			.catch((err: any) => {
				if (err?.response?.status === 401) {
					logOut();
				}
				message.error(err.response.data.message);
			});
	};

	const columns = [
		{
			title: 'ID',
			dataIndex: 'offerId',
			key: 'id',
			render: (data: string) => {
				return data;
			},
		},
		{
			title: 'Amount',
			dataIndex: 'amount',
			key: 'amount',
			render: (
				data: number,
				row: { offer: { amount: number; currency?: string } }
			) =>
				`${getSymbolFromCurrency(
					row.offer.currency ? row.offer.currency : currency
				)} ${String(row.offer.amount / 100).replace(/\B(?=(\d{3})+(?!\d))/g, ',')}`,
		},
		{
			title: 'RR',
			dataIndex: 'rr',
			key: 'rr',
			render: (data: number, row: { offer: { repaymentRate: number } }) =>
				`${row.offer.repaymentRate}%`,
		},
		{
			title: 'Fee',
			dataIndex: 'fee',
			key: 'fee',
			render: (data: number, row: { offer: { fee: number; currency?: string } }) =>
				`${getSymbolFromCurrency(
					row.offer.currency ? row.offer.currency : currency
				)} ${String(row.offer.fee / 100).replace(/\B(?=(\d{3})+(?!\d))/g, ',')}`,
		},
		{
			title: 'Expiry Date',
			dataIndex: 'expiryDate',
			key: 'expiryDate',
			render: (data: Date, row: { offer: { validDate: Date } }) =>
				`${row.offer.validDate}`,
		},
		(role?.permissions?.includes(OfferControllerPermission.ReadOffer) ||
			level === AdminControllerPermission.SUPER_ADMIN) && {
			title: 'Action',
			key: 'action',
			// eslint-disable-next-line @typescript-eslint/no-explicit-any
			render: (text: string, row: any) => (
				<>
					<Space size="small">
						<a
							onClick={() => {
								showOfferModal(row);
							}}
						>
							{'Details'}
						</a>
					</Space>
					{row.status !== 'DONE' ? (
						<>
							{/* <Space className="px-1">{'|'}</Space> */}
							<Space size="small">
								<a
									onClick={() => {
										showOfferEditModal(row);
									}}
								>
									{'| Edit'}
								</a>
							</Space>
						</>
					) : (
						``
					)}
				</>
			),
		},
	];

	// offer Details modal

	const [isOfferDetailsModalVisible, setIsOfferDetailsModalVisible] =
		useState(false);

	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	const [offerDetails, setOfferDetails] = useState<any>(null);

	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	const showOfferModal = (row: any) => {
		setOfferDetails(row);
		setIsOfferDetailsModalVisible(true);
	};
	const showOfferEditModal = (row: any) => {
		setOfferDetails(row);
		editForm.setFieldsValue({
			...row,
			...row.offer,
			amount: row.offer.amount / 100,
			fee: row.offer.fee / 100,
			minRepayment: row?.minRepayment ? row?.minRepayment / 100 : null,
			maxRepayment: row?.maxRepayment ? row?.maxRepayment / 100 : null,
			expectedRepayment: row?.expectedRepayment
				? row?.expectedRepayment / 100
				: null,
			expectedRevenue: row?.expectedRevenue ? row?.expectedRevenue / 100 : null,
			validity: moment(row.offer.validDate),
			expectedIRR: row?.expectedIRR ? row?.expectedIRR * 100 : null,
			expectedROCE: row?.expectedROCE ? row?.expectedROCE * 100 : null,
			sources: row?.revSources?.map((item: any) =>
				JSON.stringify({
					platform: item.platform,
					sourceId: item.sourceId,
				})
			),
		});
		setOfferEditModal(true);
	};

	const handleOfferCancel = () => {
		setIsOfferDetailsModalVisible(false);
	};
	const handleOfferFormEdit = () => {
		setIsOfferDetailsModalVisible(false);
	};

	const [revSources, setRevSources] = useState([]);

	const getRevSourceData = async (id: string) => {
		if (
			checkPermission(
				ConnectControllerPermission.ClientRevenueSourceList,
				role,
				level
			)
		) {
			const data = await getRevenueSourceInfo(id);
			setRevSources(data);
		}
	};

	const revSourceOptions =
		revSources.length &&
		revSources.map((SourceObject: any) => {
			return {
				label: `${SourceObject.platform}-${
					SourceObject.name ? SourceObject.name : 'N/A'
				}-${SourceObject.currency}`,

				value: JSON.stringify({
					platform: SourceObject.platform,
					sourceId: SourceObject.sourceId,
				}),
			};
		});

	useEffect(() => {
		getRevSourceData(clientId);
	}, [clientInfo]);

	const disablePastDates = (current: number | moment.Moment) =>
		current && current < moment().startOf('day');
	const { Option } = Select;
	return (
		<div>
			{/* Offer Details Modal */}
			<Modal
				title={
					<Row align="middle">
						<h3 style={{ margin: '16px 16px 16px 0px' }}>Offer Details</h3>
					</Row>
				}
				visible={isOfferDetailsModalVisible}
				onCancel={handleOfferCancel}
				footer={null}
			>
				<Form
					name="basic"
					layout="vertical"
					requiredMark={false}
					onFinish={handleOfferFormEdit}
				>
					<div className={clientListStyles.transactionDetailsformContent}>
						<Row>
							<Col span={12}>
								<b>Offer Id</b>
							</Col>
							<Col span={12}>{offerDetails !== null && offerDetails.offerId}</Col>
						</Row>
						<Row>
							<Col span={12}>
								<b>Offer Name</b>
							</Col>
							<Col span={12}>{offerDetails !== null && offerDetails.offer.name}</Col>
						</Row>
						<Row>
							<Col span={12}>
								<b>Offer Description</b>
							</Col>
							<Col span={12}>
								{offerDetails !== null && offerDetails.offer.description}
							</Col>
						</Row>
						<Row>
							<Col span={12}>
								<b>Currency</b>
							</Col>
							<Col span={12}>
								{offerDetails !== null && offerDetails.offer.currency}
							</Col>
						</Row>
						<Row>
							<Col span={12}>Rev Sources</Col>
							<Col span={12}>
								{offerDetails?.revSources.map((item: any) => {
									return (
										<span>
											{`${item.platform}-${item.name ? item.name : 'N/A'}-${
												item.currency
											}`}
											<br />
										</span>
									);
								})}
							</Col>
						</Row>
						<Row>
							<Col span={12}>
								<b>Repayment Gateway</b>
							</Col>
							<Col span={12}>
								{offerDetails !== null && offerDetails.repaymentGateway}
							</Col>
						</Row>
						<Row>
							<Col span={12}>
								<b>Offer Amount</b>
							</Col>
							<Col span={12}>
								{}
								{`${getSymbolFromCurrency(offerDetails?.offer?.currency)} ${
									offerDetails !== null &&
									String(offerDetails.offer.amount / 100).replace(
										/\B(?=(\d{3})+(?!\d))/g,
										','
									)
								}`}
							</Col>
						</Row>
						<Row>
							<Col span={12}>
								<b>Repayment Rate</b>
							</Col>
							<Col span={12}>
								{offerDetails !== null && offerDetails.offer.repaymentRate}%
							</Col>
						</Row>
						<Row>
							<Col span={12}>
								<b>Fee</b>
							</Col>
							<Col span={12}>
								{`${getSymbolFromCurrency(offerDetails?.offer?.currency)} ${
									offerDetails !== null &&
									String(offerDetails.offer.fee / 100).replace(
										/\B(?=(\d{3})+(?!\d))/g,
										','
									)
								}`}
							</Col>
						</Row>
						<Row>
							<Col span={12}>
								<b>Expected Repayment</b>
							</Col>
							<Col span={12}>
								{`${getSymbolFromCurrency(offerDetails?.offer?.currency)} ${
									offerDetails !== null &&
									String(offerDetails?.expectedRepayment / 100).replace(
										/\B(?=(\d{3})+(?!\d))/g,
										','
									)
								}`}
							</Col>
						</Row>
						<Row>
							<Col span={12}>
								<b>Expected Revenue</b>
							</Col>
							<Col span={12}>
								{`${getSymbolFromCurrency(offerDetails?.offer?.currency)} ${
									offerDetails !== null &&
									String(offerDetails?.expectedRevenue / 100).replace(
										/\B(?=(\d{3})+(?!\d))/g,
										','
									)
								}`}
							</Col>
						</Row>
						<Row>
							<Col span={12}>
								<b>Expected ROCE</b>
							</Col>
							<Col span={12}>{offerDetails?.expectedROCE * 100}%</Col>
						</Row>
						<Row>
							<Col span={12}>
								<b>Expected IRR</b>
							</Col>
							<Col span={12}>{offerDetails?.expectedIRR * 100}%</Col>
						</Row>
						<Row>
							<Col span={12}>
								<b>Repayment Frequency</b>
							</Col>
							<Col span={12}>{offerDetails?.repaymentFrequency}</Col>
						</Row>
						<Row>
							<Col span={12}>
								<b>Holiday Period(Days)</b>
							</Col>
							<Col span={12}>{offerDetails?.holidayPeriod}</Col>
						</Row>
						<Row>
							<Col span={12}>
								<b>Maximum Repayment</b>
							</Col>
							<Col span={12}>{offerDetails?.maxRepayment / 100}</Col>
						</Row>
						<Row>
							<Col span={12}>
								<b>Minimum Repayment</b>
							</Col>
							<Col span={12}>{offerDetails?.minRepayment / 100}</Col>
						</Row>
						<Row>
							<Col span={12}>
								<b>Expiry Date</b>
							</Col>
							<Col span={12}>
								{offerDetails !== null &&
									moment(offerDetails?.offer?.validDate).format('YYYY-MM-DD')}
							</Col>
						</Row>
						<Row>
							<Col span={12}>
								<b>Created At</b>
							</Col>
							<Col span={12}>
								{offerDetails !== null &&
									moment(offerDetails.createdAt).format('YYYY-MM-DD')}
							</Col>
						</Row>
						<Row>
							<Col span={12}>
								<b>Updated At</b>
							</Col>
							<Col span={12}>
								{offerDetails !== null &&
									moment(offerDetails.updatedAt).format('YYYY-MM-DD')}
							</Col>
						</Row>
						<br />
						<Row>
							<Col span={12}>
								<a href={offerDetails?.pandaDocClientLink} target="_blank">
									<b>Client Execution Link</b>
								</a>
							</Col>
						</Row>
						<Row>
							<Col span={12}>
								<a href={offerDetails?.pandaDocValerianLink} target="_blank">
									<b>Valerian Execution Link</b>
								</a>
							</Col>
						</Row>
					</div>
				</Form>
			</Modal>
			{/* Create Offer Modal */}
			<Modal
				title={
					<Row align="middle">
						<h3 style={{ margin: '16px 16px 16px 0px' }}>Create Offer</h3>
					</Row>
				}
				visible={isOfferModalVisible}
				onCancel={handleCancel}
				footer={null}
			>
				<div>
					<Col>
						<h4 style={{ margin: 0 }}>{companyName}</h4>
					</Col>
					<Col>
						<Row className={OfferStyle.Clientmodal_spanContainingRow}>
							<span>
								<b>ID:</b>
							</span>
							<span> {clientId.toUpperCase()}</span>
						</Row>
					</Col>
				</div>
				<br />
				<Form
					name="basic"
					layout="vertical"
					requiredMark={false}
					form={form}
					onFinish={handleFormSubmit}
				>
					<Form.Item label="Name" name="name">
						<Input name="name" placeholder="Name" required />
					</Form.Item>
					<Form.Item label="Description" name="description">
						<TextArea
							name="description"
							rows={4}
							showCount
							maxLength={1000}
							required
						/>
					</Form.Item>
					{checkPermission(
						ConnectControllerPermission.ClientMandateInfo,
						role,
						level
					) && (
						<Form.Item label="Mandate" name="goCardlessMandate">
							<Select allowClear>
								{mandate?.map((item: any) => (
									<Option
										value={item.mandate}
									>{`${item.mandate}-${item.country}-${item.scheme}`}</Option>
								))}
							</Select>
						</Form.Item>
					)}
					{checkPermission(
						ConnectControllerPermission.ClientRevenueSourceList,
						role,
						level
					) && (
						<Form.Item label="Revenue Sources" name="sources">
							<Select
								mode="multiple"
								showArrow
								style={{ width: '100%' }}
								options={revSourceOptions}
							/>
						</Form.Item>
					)}
					<Row>
						<Col span={12}>
							{' '}
							<Form.Item
								label="Repayment Gateway"
								name="repaymentGateway"
								initialValue={'GoCardless_LU'}
								rules={[
									{
										required: true,
										message: 'Please select your Repayment Gateway',
									},
								]}
							>
								<Select>
									<>
										<Option value="GoCardless_LU">GoCardless LU</Option>
										<Option value="GoCardless_UK">GoCardless UK</Option>
										<Option value="STRIPE">STRIPE</Option>
									</>
								</Select>
							</Form.Item>
						</Col>
						<Col span={12}>
							{' '}
							<Form.Item
								label="Select Currency"
								name="currency"
								rules={[
									{
										required: true,
										message: 'Please select your Revenue currency',
									},
								]}
							>
								<Select>
									{currencyList.map((data: { cid: number; currency: string }) => (
										<Option
											value={data.currency}
											key={data.cid}
											defaultValue={getSymbolFromCurrency(currency)}
										>
											{data.currency}({getSymbolFromCurrency(data.currency)})
										</Option>
									))}
								</Select>
							</Form.Item>
						</Col>
					</Row>
					<Row>
						<Col span={12}>
							{' '}
							<Form.Item label="Amount">
								<Input.Group compact>
									<Form.Item name="currency" noStyle>
										<Input
											style={{ width: '30%', textAlign: 'center' }}
											name="currency"
											defaultValue={getSymbolFromCurrency(currency)}
											disabled
										/>
									</Form.Item>
									<Form.Item
										name="amount"
										noStyle
										rules={[
											{
												required: true,
												message: 'Please input your requested advance amount',
											},
											{
												pattern: /^\d+(\.\d{1,2})?$/g,
												message: 'Advance amount can be only digits',
											},
										]}
									>
										<InputNumber
											style={{ width: '70%' }}
											name="amount"
											placeholder="150,000"
											formatter={(value: number) =>
												`${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
											}
										/>
									</Form.Item>
								</Input.Group>
							</Form.Item>
						</Col>
						<Col span={12}>
							{' '}
							<Form.Item label="Fee">
								<Input.Group compact>
									<Form.Item name="currency" noStyle>
										<Input
											style={{ width: '30%', textAlign: 'center' }}
											name="currency"
											defaultValue={getSymbolFromCurrency(currency)}
											disabled
										/>
									</Form.Item>
									<Form.Item
										name="fee"
										noStyle
										rules={[
											{
												required: true,
												message: 'Please input your fee amount',
											},
											{
												pattern: /^\d+(\.\d{1,2})?$/g,
												message: 'only 2 digits after decimal is accepted',
											},
										]}
									>
										<InputNumber
											style={{ width: '70%' }}
											name="fee"
											placeholder="10,000"
											formatter={(value: number) =>
												`${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
											}
										/>
									</Form.Item>
								</Input.Group>
							</Form.Item>
						</Col>
					</Row>
					<Row>
						<Col span={12}>
							{' '}
							<Form.Item
								label="Repayment Rate"
								name="repayment_rate"
								rules={[
									{
										required: true,
										message: 'Please input your repayment rate amount',
									},
									{
										pattern: /^\d+(\.\d{1,2})?$/g,
										message: 'only 2 digits after decimal is accepted',
									},
								]}
							>
								<InputNumber
									style={{ width: '100%' }}
									min={0}
									max={100}
									formatter={(rate) => `${rate}%`}
									name="repaymentRate"
								/>
							</Form.Item>
						</Col>
						<Col span={12}>
							{' '}
							<Form.Item
								label="Repayment Frequency"
								name="repaymentFrequency"
								initialValue={'DAILY'}
								// rules={[
								// 	{
								// 		required: true,
								// 		message: 'Please Select the Repayment Frequency',
								// 	},
								// ]}
							>
								<Select>
									<Option value={'DAILY'}>DAILY</Option>
									<Option value={'WEEKLY'}>WEEKLY</Option>
								</Select>
							</Form.Item>
						</Col>
					</Row>
					<Row>
						<Col span={12}>
							<Form.Item name="holidayPeriod" label="Holiday Period (Days)">
								<InputNumber min={0} style={{ width: '100%' }} />
							</Form.Item>
						</Col>
						<Col span={12}>
							<Form.Item
								label="Valid Until"
								name="validity"
								rules={[
									{
										required: true,
										message: 'Please select the validity',
									},
								]}
							>
								<DatePicker
									style={{ width: '100%' }}
									disabledDate={(current) => disablePastDates(current)}
								/>
							</Form.Item>
						</Col>
					</Row>

					<Row>
						<Col span={12}>
							<Form.Item label="Minimum Repayment">
								<Input.Group compact>
									<Form.Item name="currency" noStyle>
										<Input
											style={{ width: '30%', textAlign: 'center' }}
											name="currency"
											defaultValue={getSymbolFromCurrency(currency)}
											disabled
										/>
									</Form.Item>
									<Form.Item
										name="minRepayment"
										noStyle
										rules={[
											// {
											// 	required: true,
											// 	message: 'Please input your requested advance amount',
											// },
											{
												pattern: /^\d+(\.\d{1,2})?$/g,
												message: 'Advance amount can be only digits',
											},
										]}
									>
										<InputNumber
											style={{ width: '70%' }}
											name="minRepayment"
											placeholder="250"
											min={0}
											formatter={(value: number) =>
												`${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
											}
										/>
									</Form.Item>
								</Input.Group>
							</Form.Item>
						</Col>
						<Col span={12}>
							<Form.Item label="Maximum Repayment">
								<Input.Group compact>
									<Form.Item name="currency" noStyle>
										<Input
											style={{ width: '30%', textAlign: 'center' }}
											name="currency"
											defaultValue={getSymbolFromCurrency(currency)}
											disabled
										/>
									</Form.Item>
									<Form.Item
										name="maxRepayment"
										noStyle
										rules={[
											// {
											// 	required: true,
											// 	message: 'Please input your requested advance amount',
											// },
											{
												pattern: /^\d+(\.\d{1,2})?$/g,
												message: 'Advance amount can be only digits',
											},
										]}
									>
										<InputNumber
											style={{ width: '70%' }}
											name="maxRepayment"
											placeholder="5,000"
											min={0}
											formatter={(value: number) =>
												`${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
											}
										/>
									</Form.Item>
								</Input.Group>
							</Form.Item>
						</Col>
					</Row>
					<Row>
						<Col span={12}>
							{' '}
							<Form.Item label="Expected Repayment Amount">
								<Input.Group compact>
									<Form.Item name="currency" noStyle>
										<Input
											style={{ width: '30%', textAlign: 'center' }}
											name="currency"
											defaultValue={getSymbolFromCurrency(currency)}
											disabled
										/>
									</Form.Item>
									<Form.Item
										name="expectedRepayment"
										noStyle
										rules={[
											{
												pattern: /^\d+(\.\d{1,2})?$/g,
												message: 'Advance amount can be only digits',
											},
										]}
									>
										<InputNumber
											style={{ width: '70%' }}
											name="expectedRepayment"
											placeholder="1,000"
											formatter={(value: number) =>
												`${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
											}
										/>
									</Form.Item>
								</Input.Group>
							</Form.Item>
						</Col>
						<Col span={12}>
							{' '}
							<Form.Item label="Expected Revenue">
								<Input.Group compact>
									<Form.Item name="currency" noStyle>
										<Input
											style={{ width: '30%', textAlign: 'center' }}
											name="currency"
											defaultValue={getSymbolFromCurrency(currency)}
											disabled
										/>
									</Form.Item>
									<Form.Item
										name="expectedRevenue"
										noStyle
										rules={[
											{
												pattern: /^\d+(\.\d{1,2})?$/g,
												message: 'Advance amount can be only digits',
											},
										]}
									>
										<InputNumber
											style={{ width: '70%' }}
											name="expectedRevenue"
											placeholder="20,000"
											formatter={(value: number) =>
												`${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
											}
										/>
									</Form.Item>
								</Input.Group>
							</Form.Item>
						</Col>
					</Row>
					<Row>
						<Col span={12}>
							{' '}
							<Form.Item name="expectedROCE" label="Expected ROCE">
								<InputNumber
									style={{ width: '100%' }}
									name="expectedROCE"
									placeholder="25"
									min={0}
									max={100}
									formatter={(value) => `${value}%`}
								/>
							</Form.Item>
						</Col>
						<Col span={12}>
							{' '}
							<Form.Item name="expectedIRR" label="Expected IRR">
								<InputNumber
									style={{ width: '100%' }}
									name="expectedIRR"
									placeholder="25"
									min={0}
									max={100}
									// type={'number'}
									formatter={(value) => `${value}%`}
								/>
							</Form.Item>
						</Col>
					</Row>
					<Form.Item
						label="Client Execution Link"
						name="pandaDocClientLink"
						rules={[
							{
								required: true,
								message: 'This url is required.',
							},
							{
								type: 'url',
								message: 'This field must be a valid url.',
							},
						]}
					>
						<Input
							name="pandaDocClientLink"
							placeholder="https://app.pandadoc.com/s"
							required
						/>
					</Form.Item>
					<Form.Item
						label="Valerian Execution Link"
						name="pandaDocValerianLink"
						rules={[
							{
								required: true,
								message: 'This url is required.',
							},
							{
								type: 'url',
								message: 'This field must be a valid url.',
							},
						]}
					>
						<Input
							name="pandaDocValerianLink"
							placeholder="https://app.pandadoc.com/s"
							required
						/>
					</Form.Item>
					<Form.Item
						name="sendEmailToClient"
						valuePropName="checked"
						fieldKey={['sendEmailToClient']}
						initialValue={true}
					>
						<Checkbox name="sendEmailToClient">Send Offer Email to Client</Checkbox>
					</Form.Item>
					<Row justify="center">
						<Button className="_form_submit_small" type="primary" htmlType="submit">
							Confirm
						</Button>
					</Row>
				</Form>
			</Modal>
			<OfferUpdateModal
				clientId={clientId}
				handleCancel={() => setOfferEditModal(false)}
				isOfferModalVisible={offerEditModal}
				companyName={companyName}
				form={editForm}
				handleFormSubmit={handleEditFormSubmit}
				currency={currency}
				currencyList={currencyList}
				revSourceOptions={revSourceOptions}
				offerDetails={offerDetails}
				mandate={mandate}
			/>

			<Card bordered={false}>
				<Row justify="space-between" align="middle">
					<Col>
						<h3>Offers</h3>
					</Col>
					{(role?.permissions?.includes(OfferControllerPermission.CreateOffer) ||
						level === AdminControllerPermission.SUPER_ADMIN) && (
						<Col>
							<Button
								icon={<EditOutlined />}
								type="default"
								className={OfferStyle.buttonStyle}
								size="small"
								onClick={showModal}
							>
								Create Offer
							</Button>
						</Col>
					)}
				</Row>

				<div className={OfferStyle.infoContainer}>
					<Row>
						<DateRangePicker
							onDateChange={(values: [string | undefined, string | undefined]) => {
								updateOfferData('loading', true);
								setPage(1);
								values ? setFromDate(values[0]) : setFromDate(undefined);
								values ? setToDate(values[1]) : setToDate(undefined);
							}}
						/>

						<Divider />
						<Row className="_full_width">
							<Col span={24}>
								<ListViewTable
									rowKey="ID"
									columns={columns}
									data={data}
									loading={loading}
									currentPage={page}
									total={totalData}
									onPageChange={(value: number) => {
										updateOfferData('loading', true);
										setPage(value);
									}}
								/>
							</Col>
						</Row>
					</Row>
				</div>
			</Card>
		</div>
	);
};

const mapStateToProps = (state: RootState) => ({
	contract: state.dash.contract,
	currentPage: state.dash.offer?.page,
	totalData: state.dash.offer?.total,
	data: state.dash.offer?.data,
	loading: state.dash.offer?.loading,
	clientInfo: state.dash.clientData,
	role: state.auth?.loggedInUser?.role,
	level: state.auth.loggedInUser?.level,
});

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const mapDispatchToProps = (dispatch: any) => ({
	// remove_contract: () => dispatch(removeContract()),
	// updateContract: (offerId: string) => dispatch(updateContract(offerId)),
	setOfferData: (query: string, id: string) => {
		dispatch(setClientOfferData(query, id));
	},
	updateOfferData: (query: string, id: string) => {
		dispatch(updateClientOfferData(query, id));
	},
});

export default connect(mapStateToProps, mapDispatchToProps)(OffersCard);
