import {
	Button,
	Checkbox,
	Col,
	DatePicker,
	Form,
	Input,
	InputNumber,
	Modal,
	Row,
	Select,
} from 'antd';
import TextArea from 'antd/lib/input/TextArea';
import getSymbolFromCurrency from 'currency-symbol-map';
import OfferStyle from '../../design/scss/offer.module.scss';

import React from 'react';
import moment from 'moment';
import { checkPermission } from 'utils/extra';
import ConnectControllerPermission from 'dto/permissions/connections.permissions';
import { connect } from 'react-redux';

const OfferUpdateModal: React.FC<any> = ({
	handleCancel,
	isOfferModalVisible,
	companyName,
	form,
	handleFormSubmit,
	clientId,
	currency,
	currencyList,
	revSourceOptions,
	mandate,
	role,
	level,
}) => {
	const { Option } = Select;
	const disablePastDates = (current: number | moment.Moment) =>
		current && current < moment().startOf('day');

	return (
		<Modal
			title={
				<Row align="middle">
					<h3 style={{ margin: '16px 16px 16px 0px' }}>Update Offer</h3>
				</Row>
			}
			visible={isOfferModalVisible}
			onCancel={handleCancel}
			footer={null}
		>
			<div>
				<Col>
					<h4 style={{ margin: 0 }}>{companyName}</h4>
				</Col>
				<Col>
					<Row className={OfferStyle.Clientmodal_spanContainingRow}>
						<span>
							<b>ID:</b>
						</span>
						<span> {clientId.toUpperCase()}</span>
					</Row>
				</Col>
			</div>
			<br />
			<Form
				name="basic"
				layout="vertical"
				requiredMark={false}
				form={form}
				onFinish={handleFormSubmit}
			>
				<Form.Item label="Name" name="name">
					<Input name="name" placeholder="Name" required />
				</Form.Item>
				<Form.Item label="Description" name="description">
					<TextArea
						name="description"
						rows={4}
						showCount
						maxLength={1000}
						required
					/>
				</Form.Item>
				{checkPermission(
					ConnectControllerPermission.ClientMandateInfo,
					role,
					level
				) && (
					<Form.Item label="Mandate" name="goCardlessMandate">
						<Select allowClear>
							{mandate?.map((item: any) => (
								<Option
									value={item.mandate}
								>{`${item.mandate}-${item.country}-${item.scheme}`}</Option>
							))}
						</Select>
					</Form.Item>
				)}
				{checkPermission(
					ConnectControllerPermission.ClientRevenueSourceList,
					role,
					level
				) && (
					<Form.Item label="Revenue Sources" name={'sources'}>
						<Select
							mode="multiple"
							showArrow
							style={{ width: '100%' }}
							options={revSourceOptions}
						/>
					</Form.Item>
				)}
				<Row>
					<Col span={12}>
						<Form.Item label="Repayment Gateway" name="repaymentGateway">
							<Select>
								<>
									<Option value="GoCardless_LU">GoCardless LU</Option>
									<Option value="GoCardless_UK">GoCardless UK</Option>
									<Option value="STRIPE">STRIPE</Option>
								</>
							</Select>
						</Form.Item>
					</Col>
					<Col span={12}>
						<Form.Item
							label="Select Currency"
							name="currency"
							rules={[
								{
									required: true,
									message: 'Please select your Revenue currency',
								},
							]}
						>
							<Select>
								{currencyList.map((data: { cid: number; currency: string }) => (
									<Option
										value={data.currency}
										key={data.cid}
										defaultValue={getSymbolFromCurrency(currency)}
									>
										{data.currency}({getSymbolFromCurrency(data.currency)})
									</Option>
								))}
							</Select>
						</Form.Item>
					</Col>
				</Row>
				<Row>
					<Col span={12}>
						<Form.Item label="Amount">
							<Input.Group compact>
								<Form.Item name="currency" noStyle>
									<Input
										style={{ width: '30%', textAlign: 'center' }}
										name="currency"
										defaultValue={getSymbolFromCurrency(currency)}
										disabled
									/>
								</Form.Item>
								<Form.Item
									name="amount"
									noStyle
									rules={[
										{
											required: true,
											message: 'Please input your requested advance amount',
										},
										{
											pattern: /^\d+(\.\d{1,2})?$/g,
											message: 'Advance amount can be only digits',
										},
									]}
								>
									<InputNumber
										style={{ width: '70%' }}
										name="amount"
										placeholder="500,000"
										formatter={(value: number) =>
											`${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
										}
									/>
								</Form.Item>
							</Input.Group>
						</Form.Item>
					</Col>
					<Col span={12}>
						<Form.Item label="Fee">
							<Input.Group compact>
								<Form.Item name="currency" noStyle>
									<Input
										style={{ width: '30%', textAlign: 'center' }}
										name="currency"
										defaultValue={getSymbolFromCurrency(currency)}
										disabled
									/>
								</Form.Item>
								<Form.Item
									name="fee"
									noStyle
									rules={[
										{
											required: true,
											message: 'Please input your fee amount',
										},
										{
											pattern: /^\d+(\.\d{1,2})?$/g,
											message: 'only 2 digits after decimal is accepted',
										},
									]}
								>
									<InputNumber
										style={{ width: '70%' }}
										name="amount"
										placeholder="500,000"
										formatter={(value: number) =>
											`${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
										}
									/>
								</Form.Item>
							</Input.Group>
						</Form.Item>
					</Col>
				</Row>
				<Row>
					<Col span={12}>
						{' '}
						<Form.Item
							label="Repayment Rate"
							name="repaymentRate"
							rules={[
								{
									required: true,
									message: 'Please input your repayment rate amount',
								},
								{
									pattern: /^\d+(\.\d{1,2})?$/g,
									message: 'only 2 digits after decimal is accepted',
								},
							]}
						>
							<InputNumber
								style={{ width: '100%' }}
								min={0}
								max={100}
								formatter={(rate) => `${rate}%`}
								name="repaymentRate"
							/>
						</Form.Item>
					</Col>
					<Col span={12}>
						<Form.Item
							label="Repayment Frequency"
							name="repaymentFrequency"
							rules={[
								{
									required: true,
									message: 'Please Select the Repayment Frequency',
								},
							]}
						>
							<Select>
								<Option value={'DAILY'}>DAILY</Option>
								<Option value={'WEEKLY'}>WEEKLY</Option>
							</Select>
						</Form.Item>
					</Col>
				</Row>
				<Row>
					<Col span={12}>
						<Form.Item name="holidayPeriod" label="Holiday Period (Days)">
							<InputNumber min={0} style={{ width: '100%' }} />
						</Form.Item>
					</Col>
					<Col span={12}>
						<Form.Item
							label="Valid Until"
							name="validity"
							rules={[
								{
									required: true,
									message: 'Please select the validity',
								},
							]}
						>
							<DatePicker
								style={{ width: '100%' }}
								disabledDate={(current) => disablePastDates(current)}
							/>
						</Form.Item>
					</Col>
				</Row>
				<Row>
					<Row>
						<Col span={12}>
							<Form.Item label="Minimum Repayment">
								<Input.Group compact>
									<Form.Item name="currency" noStyle>
										<Input
											style={{ width: '30%', textAlign: 'center' }}
											name="currency"
											defaultValue={getSymbolFromCurrency(currency)}
											disabled
										/>
									</Form.Item>
									<Form.Item
										name="minRepayment"
										noStyle
										rules={[
											// {
											// 	required: true,
											// 	message: 'Please input your requested minimum repayment amount',
											// },
											{
												pattern: /^\d+(\.\d{1,2})?$/g,
												message: 'Advance amount can be only digits',
											},
										]}
									>
										<InputNumber
											style={{ width: '70%' }}
											name="amount"
											placeholder="250"
											min={0}
											formatter={(value: number) =>
												`${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
											}
										/>
									</Form.Item>
								</Input.Group>
							</Form.Item>
						</Col>
						<Col span={12}>
							<Form.Item label="Maximum Repayment">
								<Input.Group compact>
									<Form.Item name="currency" noStyle>
										<Input
											style={{ width: '30%', textAlign: 'center' }}
											name="currency"
											defaultValue={getSymbolFromCurrency(currency)}
											disabled
										/>
									</Form.Item>
									<Form.Item
										name="maxRepayment"
										noStyle
										rules={[
											// {
											// 	required: true,
											// 	message: 'Please input your requested maximum repayment',
											// },
											{
												pattern: /^\d+(\.\d{1,2})?$/g,
												message: 'Advance amount can be only digits',
											},
										]}
									>
										<InputNumber
											style={{ width: '70%' }}
											name="amount"
											placeholder="5,000"
											min={0}
											formatter={(value: number) =>
												`${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
											}
										/>
									</Form.Item>
								</Input.Group>
							</Form.Item>
						</Col>
					</Row>
					<Col span={12}>
						{' '}
						<Form.Item label="Expected Repayment Amount">
							<Input.Group compact>
								<Form.Item name="currency" noStyle>
									<Input
										style={{ width: '30%', textAlign: 'center' }}
										name="currency"
										defaultValue={getSymbolFromCurrency(currency)}
										disabled
									/>
								</Form.Item>
								<Form.Item
									name="expectedRepayment"
									noStyle
									rules={[
										{
											pattern: /^\d+(\.\d{1,2})?$/g,
											message: 'Advance amount can be only digits',
										},
									]}
								>
									<InputNumber
										style={{ width: '70%' }}
										name="expectedRepayment"
										placeholder="5000"
										formatter={(value: number) =>
											`${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
										}
									/>
								</Form.Item>
							</Input.Group>
						</Form.Item>
					</Col>
					<Col span={12}>
						{' '}
						<Form.Item label="Expected Revenue">
							<Input.Group compact>
								<Form.Item name="currency" noStyle>
									<Input
										style={{ width: '30%', textAlign: 'center' }}
										name="currency"
										defaultValue={getSymbolFromCurrency(currency)}
										disabled
									/>
								</Form.Item>
								<Form.Item
									name="expectedRevenue"
									noStyle
									rules={[
										{
											pattern: /^\d+(\.\d{1,2})?$/g,
											message: 'Advance amount can be only digits',
										},
									]}
								>
									<InputNumber
										style={{ width: '70%' }}
										name="expectedRevenue"
										placeholder="5000"
										formatter={(value: number) =>
											`${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
										}
									/>
								</Form.Item>
							</Input.Group>
						</Form.Item>
					</Col>
				</Row>
				<Row>
					<Col span={12}>
						{' '}
						<Form.Item name="expectedROCE" label="Expected ROCE">
							<InputNumber
								style={{ width: '100%' }}
								name="expectedROCE"
								placeholder="25"
								min={0}
								max={100}
								formatter={(value) => `${value}%`}
							/>
						</Form.Item>
					</Col>
					<Col span={12}>
						{' '}
						<Form.Item name="expectedIRR" label="Expected IRR">
							<InputNumber
								style={{ width: '100%' }}
								name="expectedIRR"
								placeholder="25"
								min={0}
								max={100}
								// type={'number'}
								formatter={(value) => `${value}%`}
							/>
						</Form.Item>
					</Col>
				</Row>

				<Form.Item
					label="Client Execution Link"
					name="pandaDocClientLink"
					rules={[
						{
							required: true,
							message: 'This url is required.',
						},
						{
							type: 'url',
							message: 'This field must be a valid url.',
						},
					]}
				>
					<Input name="pandaDocClientLink" required />
				</Form.Item>
				<Form.Item
					label="Valerian Execution Link"
					name="pandaDocValerianLink"
					rules={[
						{
							required: true,
							message: 'This url is required.',
						},
						{
							type: 'url',
							message: 'This field must be a valid url.',
						},
					]}
				>
					<Input name="pandaDocValerianLink" required />
				</Form.Item>
				<Form.Item
					name="sendEmailToClient"
					valuePropName="checked"
					fieldKey={['sendEmailToClient']}
				>
					<Checkbox name="sendEmailToClient">Send Offer Email to Client</Checkbox>
				</Form.Item>
				<Row justify="center">
					<Button className="_form_submit_small" type="primary" htmlType="submit">
						Confirm
					</Button>
				</Row>
			</Form>
		</Modal>
	);
};

const mapStateToProps = (state: any) => ({
	role: state.auth?.loggedInUser?.role,
	level: state.auth.loggedInUser?.level,
});

export default connect(mapStateToProps)(OfferUpdateModal);
