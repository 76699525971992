/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import React from 'react';

import clientListStyles from '../../../design/scss/clientList.module.scss';
import BaseTable from './AntDBase_table';

interface Props {
	columns: any;
	data: any;
	pagination: any;
}

const RepaymentTable: React.FC<Props> = ({ columns, data, pagination }) => {
	return (
		<BaseTable
			columns={columns}
			data={data}
			pagination={pagination}
			styleClass={clientListStyles.infoContainer_table}
		/>
	);
};

export default RepaymentTable;
