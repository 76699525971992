/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useState } from 'react';
import { Col, Row, Menu, Drawer, Space, Button } from 'antd';
import MainMenu from './MainMenu';
import SubMenu from 'antd/lib/menu/SubMenu';
import navStyles from '../../design/scss/navbarComponents.module.scss';
import { MenuOutlined } from '@ant-design/icons';
import { logOut } from '../../store/actions/authActions';
import { connect } from 'react-redux';
import { APP_VERSION } from 'config';

interface TopNavProps {
	logOut: () => void;
	email: string;
}

const TopNav: React.FC<TopNavProps> = ({ logOut, email }) => {
	const [visible, setVisible] = useState(false);
	const ShowDrawer = () => {
		setVisible(true);
	};
	const CloseDrawer = () => {
		setVisible(false);
	};

	return (
		<React.Fragment>
			<div className={navStyles.navbar_container}>
				{/*Drawer */}
				<Drawer
					title="Menu"
					placement={'left'}
					closable={true}
					onClose={CloseDrawer}
					visible={visible}
					key={'left'}
					bodyStyle={{ padding: '0px' }}
				>
					<MainMenu screen="mobile" />
					<Menu mode="inline">
						<SubMenu key="sub1" title={'email'}>
							<Menu.ItemGroup key="g1">
								<Menu.Item key="logout" onClick={() => logOut()}>
									LOGOUT
								</Menu.Item>
							</Menu.ItemGroup>
						</SubMenu>
					</Menu>
				</Drawer>
				<Row justify="space-between">
					<Col>
						<Row>
							<img
								src="/luca.png"
								alt="Valerian Funds"
								className={navStyles.navbar_heroImage}
								style={{ height: '48px', paddingTop: '8px' }}
							/>
							<span className={navStyles.r2s2}>
								<i>{APP_VERSION}</i>
							</span>
						</Row>
					</Col>
					<Col className={navStyles.navbar_drawerStyle}>
						<Space>
							<Button
								type="default"
								icon={<MenuOutlined />}
								onClick={ShowDrawer}
							></Button>
						</Space>
					</Col>
					<Col className={navStyles.navbar_logOut}>
						<Menu mode="horizontal" className={navStyles.navbar_topNav}>
							<SubMenu key="sub1" title={email}>
								<Menu.ItemGroup key="g1">
									<Menu.Item key="1" onClick={() => logOut()}>
										LOGOUT
									</Menu.Item>
								</Menu.ItemGroup>
							</SubMenu>
						</Menu>
					</Col>
				</Row>
			</div>
		</React.Fragment>
	);
};

const mapStateToProps = (state: any) => ({
	email: state.auth.email,
});

const mapDispatchtoProps = (dispatch: any) => ({
	logOut: () => dispatch(logOut()),
});

export default connect(mapStateToProps, mapDispatchtoProps)(TopNav);
