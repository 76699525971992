enum OfferControllerPermission {
	CreateOffer = 'CreateOffer',
	ReadOffer = 'ReadOffer',
	UpdateOffer = 'UpdateOffer',
	DeleteOffer = 'DeleteOffer',

	UpdateOfferRevSrc = 'UpdateOfferRevSrc',

	AllOfferList = 'AllOfferList',
	ClientOfferList = 'ClientOfferList',
}

export default OfferControllerPermission;
