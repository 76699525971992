import React from 'react';
import { Table } from 'antd';
import moment from 'moment-timezone';
moment.tz.setDefault('Europe/London');

interface LogsCardsProps {
	logsData?: [
		{
			status?: string;
			amount?: number;
			dateTime?: string;
		}
	];
}

interface logData {
	status?: string;
	amount?: number;
	dateTime?: string;
}

const LogsCard: React.FC<LogsCardsProps> = ({ logsData }) => {
	const columns = [
		{
			title: 'TRX_ID',
			width: 120,
			dataIndex: 'TRX_ID',
			key: 'TRX_ID',
			fixed: 'left' as const,
		},
		{
			title: 'paymentId',
			width: 120,
			dataIndex: 'paymentId',
			key: 'paymentId',
			fixed: 'left' as const,
		},
		{
			title: 'Amount',
			width: 120,
			dataIndex: 'amount',
			key: 'amount',
			fixed: 'left' as const,
		},
		{
			title: 'CANCELLED',
			width: 120,
			key: '1',
			render: (row: logData) => {
				const dateTime = row.dateTime?.split('T');
				let str = '';
				if (dateTime) {
					str += `${dateTime[0]} ${dateTime[1].slice(0, 8)}`;
				}
				return <>{row.status === 'cancelled' ? str : ``}</>;
			},
		},
		{
			title: 'CHARGED_\nBACK',
			width: 120,
			key: '2',
			render: (row: logData) => {
				const dateTime = row.dateTime?.split('T');
				let str = '';
				if (dateTime) {
					str += `${dateTime[0]} ${dateTime[1].slice(0, 8)}`;
				}
				return <>{row.status === 'charged_back' ? str : ``}</>;
			},
		},
		{
			title: 'CHARGEBACK_\nCANCELLED',
			width: 140,
			key: '3',
			render: (row: logData) => {
				const dateTime = row.dateTime?.split('T');
				let str = '';
				if (dateTime) {
					str += `${dateTime[0]} ${dateTime[1].slice(0, 8)}`;
				}
				return <>{row.status === 'chargeback_cancelled' ? str : ``}</>;
			},
		},
		{
			title: 'CHARGEBACK_\nSETTLED',
			width: 140,
			key: '4',
			render: (row: logData) => {
				const dateTime = row.dateTime?.split('T');
				let str = '';
				if (dateTime) {
					str += `${dateTime[0]} ${dateTime[1].slice(0, 8)}`;
				}
				return <>{row.status === 'chargeback_settled' ? str : ``}</>;
			},
		},
		{
			title: 'CREATED',
			width: 120,
			key: '5',
			render: (row: logData) => {
				const dateTime = row.dateTime?.split('T');
				let str = '';
				if (dateTime) {
					str += `${dateTime[0]} ${dateTime[1].slice(0, 8)}`;
				}
				return <>{row.status === 'created' ? str : ``}</>;
			},
		},
		{
			title: 'CONFIRMED',
			width: 120,
			key: '6',
			render: (row: logData) => {
				const dateTime = row.dateTime?.split('T');
				let str = '';
				if (dateTime) {
					str += `${dateTime[0]} ${dateTime[1].slice(0, 8)}`;
				}
				return <>{row.status === 'confirmed' ? str : ``}</>;
			},
		},
		{
			title: 'CUSTOMER_\nAPPRROVAL_\nGRANTED',
			width: 120,
			key: '7',
			render: (row: logData) => {
				const dateTime = row.dateTime?.split('T');
				let str = '';
				if (dateTime) {
					str += `${dateTime[0]} ${dateTime[1].slice(0, 8)}`;
				}
				return <>{row.status === 'customer_approval_granted' ? str : ``}</>;
			},
		},
		{
			title: 'CUSTOMER_\nAPPRROVAL_\nDENIED',
			width: 120,
			key: '8',
			render: (row: logData) => {
				const dateTime = row.dateTime?.split('T');
				let str = '';
				if (dateTime) {
					str += `${dateTime[0]} ${dateTime[1].slice(0, 8)}`;
				}
				return <>{row.status === 'customer_approval_denied' ? str : ``}</>;
			},
		},
		{
			title: 'FAILED',
			width: 120,
			key: '9',
			render: (row: logData) => {
				const dateTime = row.dateTime?.split('T');
				let str = '';
				if (dateTime) {
					str += `${dateTime[0]} ${dateTime[1].slice(0, 8)}`;
				}
				return <>{row.status === 'failed' ? str : ``}</>;
			},
		},
		{
			title: 'LATE_\nFAILURE_\nSETTLED',
			width: 140,
			key: '10',
			render: (row: logData) => {
				const dateTime = row.dateTime?.split('T');
				let str = '';
				if (dateTime) {
					str += `${dateTime[0]} ${dateTime[1].slice(0, 8)}`;
				}
				return <>{row.status === 'late_failed_settled' ? str : ``}</>;
			},
		},
		{
			title: 'PAID_\nOUT',
			width: 120,
			key: '11',
			render: (row: logData) => {
				const dateTime = row.dateTime?.split('T');
				let str = '';
				if (dateTime) {
					str += `${dateTime[0]} ${dateTime[1].slice(0, 8)}`;
				}
				return <>{row.status === 'paid_out' ? str : ``}</>;
			},
		},
		{
			title: 'PENDING_\nSUBMISSION',
			width: 120,
			key: '12',
			render: (row: logData) => {
				const dateTime = row.dateTime?.split('T');
				let str = '';
				if (dateTime) {
					str += `${dateTime[0]} ${dateTime[1].slice(0, 8)}`;
				}
				return <>{row.status === 'pending_submission' ? str : ``}</>;
			},
		},
		{
			title: 'RESUBMISSION_\nREQUESTED',
			width: 140,
			key: '13',
			render: (row: logData) => {
				const dateTime = row.dateTime?.split('T');
				let str = '';
				if (dateTime) {
					str += `${dateTime[0]} ${dateTime[1].slice(0, 8)}`;
				}
				return <>{row.status === 'resubmission_requested' ? str : ``}</>;
			},
		},
		{
			title: 'SUBMITTED',
			width: 120,
			key: '14',
			render: (row: logData) => {
				const dateTime = row.dateTime?.split('T');
				let str = '';
				if (dateTime) {
					str += `${dateTime[0]} ${dateTime[1].slice(0, 8)}`;
				}
				return <>{row.status === 'submitted' ? str : ``}</>;
			},
		},
		{
			title: 'SURCHARGE_\nFEE_\nDEBITED',
			width: 120,
			key: '15',
			render: (row: logData) => {
				const dateTime = row.dateTime?.split('T');
				let str = '';
				if (dateTime) {
					str += `${dateTime[0]} ${dateTime[1].slice(0, 8)}`;
				}
				return <>{row.status === 'surcharge_fee_debited' ? str : ``}</>;
			},
		},
	];

	return (
		<>
			<Table
				columns={columns}
				pagination={false}
				dataSource={logsData}
				bordered
				scroll={{ x: 800 }}
				summary={(pageData) => {
					let total_cancelled = 0;
					let total_charged_back = 0;
					let total_chargeback_cancelled = 0;
					let total_chargeback_settled = 0;
					let total_created = 0;
					let total_confirmed = 0;
					let total_customer_approval_granted = 0;
					let total_customer_approval_denied = 0;
					let total_failed = 0;
					let total_late_failed_settled = 0;
					let total_paid_out = 0;
					let total_pending_submission = 0;
					let total_resubmission_requested = 0;
					let total_submitted = 0;
					let total_surcharge_fee_debited = 0;

					pageData.map((item) => {
						{
							item.status === 'cancelled'
								? (total_cancelled += item.amount)
								: (total_cancelled += 0);
						}
						{
							item.status === 'charged_back'
								? (total_charged_back += item.amount)
								: (total_charged_back += 0);
						}
						{
							item.status === 'chargeback_cancelled'
								? (total_chargeback_cancelled += item.amount)
								: (total_chargeback_cancelled += 0);
						}
						{
							item.status === 'chargeback_settled'
								? (total_chargeback_settled += item.amount)
								: (total_chargeback_settled += 0);
						}
						{
							item.status === 'created'
								? (total_created += item.amount)
								: (total_created += 0);
						}
						{
							item.status === 'confirmed'
								? (total_confirmed += item.amount)
								: (total_confirmed += 0);
						}
						{
							item.status === 'customer_approval_granted'
								? (total_customer_approval_granted += item.amount)
								: (total_customer_approval_granted += 0);
						}
						{
							item.status === 'customer_approval_denied'
								? (total_customer_approval_denied += item.amount)
								: (total_customer_approval_denied += 0);
						}
						{
							item.status === 'failed'
								? (total_failed += item.amount)
								: (total_failed += 0);
						}
						{
							item.status === 'late_failed_settled'
								? (total_late_failed_settled += item.amount)
								: (total_late_failed_settled += 0);
						}
						{
							item.status === 'paid_out'
								? (total_paid_out += item.amount)
								: (total_paid_out += 0);
						}
						{
							item.status === 'pending_submission'
								? (total_pending_submission += item.amount)
								: (total_pending_submission += 0);
						}
						{
							item.status === 'resubmission_requested'
								? (total_resubmission_requested += item.amount)
								: (total_resubmission_requested += 0);
						}
						{
							item.status === 'submitted'
								? (total_submitted += item.amount)
								: (total_submitted += 0);
						}
						{
							item.status === 'surcharge_fee_debited'
								? (total_surcharge_fee_debited += item.amount)
								: (total_surcharge_fee_debited += 0);
						}
					});

					return (
						<>
							<Table.Summary.Row>
								<Table.Summary.Cell index={0}>
									<h4>
										<strong> Total</strong>
									</h4>
								</Table.Summary.Cell>
								<Table.Summary.Cell index={1}> </Table.Summary.Cell>
								<Table.Summary.Cell index={2}> </Table.Summary.Cell>
								<Table.Summary.Cell index={3}>{total_cancelled}</Table.Summary.Cell>
								<Table.Summary.Cell index={4}>{total_charged_back}</Table.Summary.Cell>
								<Table.Summary.Cell index={5}>
									{total_chargeback_cancelled}
								</Table.Summary.Cell>
								<Table.Summary.Cell index={6}>
									{total_chargeback_settled}
								</Table.Summary.Cell>
								<Table.Summary.Cell index={7}>{total_created}</Table.Summary.Cell>
								<Table.Summary.Cell index={8}>{total_confirmed}</Table.Summary.Cell>
								<Table.Summary.Cell index={9}>
									{total_customer_approval_granted}
								</Table.Summary.Cell>
								<Table.Summary.Cell index={10}>
									{total_customer_approval_denied}
								</Table.Summary.Cell>
								<Table.Summary.Cell index={11}>{total_failed}</Table.Summary.Cell>
								<Table.Summary.Cell index={12}>
									{total_late_failed_settled}
								</Table.Summary.Cell>
								<Table.Summary.Cell index={13}>{total_paid_out}</Table.Summary.Cell>
								<Table.Summary.Cell index={14}>
									{total_pending_submission}
								</Table.Summary.Cell>
								<Table.Summary.Cell index={15}>
									{total_resubmission_requested}
								</Table.Summary.Cell>
								<Table.Summary.Cell index={16}>{total_submitted}</Table.Summary.Cell>
								<Table.Summary.Cell index={17}>
									{total_surcharge_fee_debited}
								</Table.Summary.Cell>
							</Table.Summary.Row>
						</>
					);
				}}
			/>
		</>
	);
};

export default LogsCard;
