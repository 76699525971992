/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import React from 'react';
import { Card, Table } from 'antd';
import clientInfoStyles from '../../design/scss/clientInfo.module.scss';
import { connect } from 'react-redux';
import { RootState } from 'dto/redux.action.dto';
import { ClientStateData } from 'dto/admin.client.dto';

interface Props {
	data: ClientStateData[];
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	column: any;
	clientId: string | null;
}

const ClientProgressCard: React.FC<Props> = ({ data, column }) => {
	return (
		<Card bordered={false} className={clientInfoStyles.Stepper_card}>
			<Table dataSource={data} columns={column} pagination={false} />
		</Card>
	);
};

const mapStateToProps = (state: RootState) => ({
	clientId: state.dash.clientData.clientId,
});

export default connect(mapStateToProps)(ClientProgressCard);
