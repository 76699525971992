import React, { useState } from 'react';
import { Button, Col, Row, Tag } from 'antd';
import connectedCardStyle from '../../design/scss/connectedCards.module.scss';
import { connect } from 'react-redux';
import DefaultSourceModal from 'components/DefaultSourceModal/DefaultSourceModal';
import clientListStyles from '../../design/scss/clientList.module.scss';
import { ClientInfo } from '../ClientInfoProps/clientInfo';
import { RootState } from 'dto/redux.action.dto';

//payment array icons
import stripeImage from 'public/images/newImages/stripe.svg';
import stripeticked from 'public/images/newImages/stripeTicked.svg';
import paypal from 'public/images/newImages/paypalGrey.svg';
import adyen from 'public/images/newImages/adyengrey.svg';
import quickplay from 'public/images/newImages/quickplaygrey.svg';

import shopifyImage from 'public/images/newImages/shopify.svg';
import shopifyTicked from 'public/images/newImages/shopifyTicked.svg';
import prestashopImageGrey from 'public/images/newImages/prestashopGrey.svg';

import amazonImage from 'public/images/newImages/amazongrey.svg';
import wooComImage from 'public/images/newImages/woogrey.svg';

import googleAdsImage from 'public/images/newImages/googleAds.svg';
import googleAnalyticsImage from 'public/images/newImages/googleAnalytics.svg';
import facebookLogo from 'public/images/newImages/facebookLogo.svg';
import ClientControllerPermission from 'dto/permissions/client.permission';
import AdminControllerPermission from 'dto/permissions/admin.permission';
// import paypalTicked from 'public/images/newImages/paypalTicked.svg';
// import prestashopTicked from 'public/images/newImages/prestashopTicked.svg';
// import amazonImageTicked from 'public/images/newImages/amazonticked.svg';
// import wooComImageTicked from 'public/images/newImages/wooticked.svg';
// import googleAdsTicked from 'public/images/newImages/googleAdsTicked.svg';
// import googleAnalyticsTicked from 'public/images/newImages/googleAnalyticsTicked.svg';
// import facebookLogoTicked from 'public/images/newImages/facebookLogoTicked.svg';

interface Props {
	stripe: boolean;
	shopify: boolean;
	clientInfo: ClientInfo;
	role: { permissions: string[] };
	level: string;
}

const ConnectedCards: React.FC<Props> = ({
	stripe,
	shopify,
	clientInfo,
	role,
	level,
}) => {
	const [selectDefault, setSelectDefault] = useState(false);

	const setSourceStyle = (value: string | null) => {
		let color = 'default';
		if (value === 'STRIPE') {
			color = 'blue';
		} else if (value === 'SHOPIFY') {
			color = 'green';
		} else if (value === 'WOCOMMERCE') {
			color = 'orange';
		} else {
			color = 'grey';
		}
		return (
			<Tag color={color} key={value} className={clientListStyles.sourceButton}>
				{value}
			</Tag>
		);
	};

	const handleShowSelectDefault = () => {
		setSelectDefault(true);
	};

	const handleCloseSelectDefault = () => {
		setSelectDefault(false);
	};

	return (
		<>
			<DefaultSourceModal
				visible={selectDefault}
				onExitView={handleCloseSelectDefault}
				clientInfo={clientInfo}
			/>
			<div className={connectedCardStyle.container}>
				<p>PSP</p>
				<Row className={connectedCardStyle.imageHolders}>
					{stripe ? (
						<img src={stripeticked} alt="stripeTicked" />
					) : (
						<img src={stripeImage} alt="stripe" />
					)}

					<img src={paypal} alt="paypal" />
					<img src={adyen} alt="adyen" />
					<img src={quickplay} alt="quickplay" />
				</Row>
				<p>E-commerce</p>
				<Row className={connectedCardStyle.imageHolders}>
					{shopify ? (
						<img src={shopifyTicked} alt="shopifyTicked" />
					) : (
						<img src={shopifyImage} alt="shopifyImage" />
					)}

					<img src={prestashopImageGrey} alt="prestashopImageGrey" />
					<img src={amazonImage} alt="amazonImage" />
					<img src={wooComImage} alt="wooComImage" />
				</Row>
				<p>Marketing</p>
				<Row className={connectedCardStyle.imageHolders}>
					<img src={googleAdsImage} alt="googleAdsImage" />
					<img src={googleAnalyticsImage} alt="googleAnalyticsImage" />
					<img src={facebookLogo} alt="facebookLogo" />
				</Row>
				<br></br>
				<Row>
					<Col>
						{role?.permissions?.includes(
							ClientControllerPermission.SetDefaultPlatform
						) || level === AdminControllerPermission.SUPER_ADMIN ? (
							<Button
								className="adminBtnStyle"
								onClick={() => handleShowSelectDefault()}
							>
								{`Select Default Source>`}
							</Button>
						) : (
							<></>
						)}
					</Col>
					<Col offset={1}>
						{clientInfo !== null && clientInfo.businessPlatform.defaultSource
							? setSourceStyle(clientInfo.businessPlatform.defaultSource)
							: setSourceStyle('NA')}
					</Col>
				</Row>
			</div>
		</>
	);
};

const mapStateToProps = (state: RootState) => ({
	stripe: state.dash.clientData.businessPlatform.STRIPE,
	shopify: state.dash.clientData.businessPlatform.SHOPIFY,
	clientInfo: state.dash.clientData,
	role: state.auth?.loggedInUser?.role,
	level: state.auth?.loggedInUser?.level,
});

export default connect(mapStateToProps)(ConnectedCards);
