import { LoadingOutlined, PaperClipOutlined } from '@ant-design/icons';
import { Button, Form, message, Upload } from 'antd';
import axios from 'axios';
import { BASE_URL } from 'config';
import { RootState } from 'dto/redux.action.dto';
import React, { useState } from 'react';
import { connect } from 'react-redux';
import { uploadContract } from 'store/actions/dashboardActions';

interface Props {
	contract: { url: string; _id: string };
	uploadContract: (contract: string) => void;
	clientId: string;
	accessToken: string | null;
}

const UploadContract: React.FC<Props> = ({
	contract,
	uploadContract,
	clientId,
	accessToken,
}) => {
	const [loading, setLoading] = useState(false);

	return (
		<div>
			<Form.Item label="Contract" name="contract">
				<Upload
					name="contract"
					showUploadList={false}
					customRequest={async (options) => {
						const { file } = options;
						setLoading(true);
						const fmData = new FormData();
						const config = {
							headers: {
								'content-type': 'multipart/form-data',
								Authorization: `Bearer ${accessToken}`,
							},
							beforeUpload(file: { size: number }) {
								const isLt10M = file.size / 1024 / 1024 < 10;
								if (!isLt10M) {
									message.error('Image must smaller than 10MB!');
									return false;
								}
								return true;
							},

							// eslint-disable-next-line @typescript-eslint/no-explicit-any
							onUploadProgress: (event: any) => {
								const percent = Math.floor((event.loaded / event.total) * 100);
								if (percent === 100) {
									// setTimeout(() => setProgress(0), 1000);
								}
							},
						};
						fmData.append('file', file);
						fmData.append('clientId', clientId);
						fmData.append('fileType', 'contract');
						try {
							const res = await axios.post(
								`${BASE_URL}upload/client-file`,
								fmData,
								config
							);
							uploadContract(res.data);
							setLoading(false);
						} catch (err) {
							message.error('Upload Fail');
							setLoading(false);
						}
					}}
				>
					<Button type="primary" icon={loading && <LoadingOutlined />}>
						Upload
					</Button>
				</Upload>
				{contract.url.length >= 1 && (
					<a href={contract.url}>
						{' '}
						<PaperClipOutlined /> Contract
					</a>
				)}
			</Form.Item>
		</div>
	);
};

const mapStateToProps = (state: RootState) => ({
	contract: state.dash.contract,
	accessToken: state.auth.accessToken,
});

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const mapDispatchToProps = (dispatch: any) => ({
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	uploadContract: (contract: any) => {
		dispatch(uploadContract(contract));
	},
});

export default connect(mapStateToProps, mapDispatchToProps)(UploadContract);
