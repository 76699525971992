/* eslint-disable @typescript-eslint/no-explicit-any */
import {
	Button,
	Checkbox,
	Col,
	DatePicker,
	Form,
	Input,
	InputNumber,
	Modal,
	Row,
	Select,
} from 'antd';
import TextArea from 'antd/lib/input/TextArea';
import React, { useEffect, useState } from 'react';
import clientListStyles from 'design/scss/clientList.module.scss';
import { getRevenueSourceInfo } from 'services/client';

interface Props {
	isAdvanceEditModalVisible: boolean;
	handleAdvanceEditCancel: () => void;
	selectedData: any;
	editForm: any;
	handleAdvanceEditFormSubmit: (values: any) => void;
}

const EditAdvanceMetaData: React.FC<Props> = ({
	isAdvanceEditModalVisible,
	handleAdvanceEditCancel,
	selectedData,
	editForm,
	handleAdvanceEditFormSubmit,
}) => {
	const [revSources, setRevSources] = useState([]);

	const getRevSourceData = async (id: string) => {
		const data = await getRevenueSourceInfo(id);
		setRevSources(data);
	};

	useEffect(() => {
		if (selectedData?.clientId) getRevSourceData(selectedData?.clientId);
	}, [selectedData]);

	const revSourceOptions = revSources?.map((SourceObject: any) => {
		return {
			label: `${SourceObject.platform}-${
				SourceObject.name ? SourceObject.name : 'N/A'
			}-${SourceObject.currency}`,

			value: JSON.stringify({
				platform: SourceObject.platform,
				sourceId: SourceObject.sourceId,
			}),
		};
	});

	const { Option } = Select;
	return (
		<Modal
			title={
				<Row align="middle">
					<h3 style={{ margin: '16px 16px 16px 0px' }}>Update Advance Metadata</h3>
				</Row>
			}
			visible={isAdvanceEditModalVisible}
			onCancel={handleAdvanceEditCancel}
			footer={null}
		>
			<div>
				<Col>
					<Row className={clientListStyles.Clientmodal_spanContainingRow}>
						<span>
							<b>ID:</b>
						</span>
						<span>{selectedData !== null && selectedData.clientId}</span>
					</Row>
				</Col>
				<Col>
					<Row className={clientListStyles.Clientmodal_spanContainingRow}>
						<span>
							<b>Advance ID:</b>
						</span>
						<span>{selectedData !== null && selectedData.advanceId}</span>
					</Row>
				</Col>
			</div>
			<br />
			<Form
				name="basic"
				layout="vertical"
				requiredMark={false}
				form={editForm}
				onFinish={handleAdvanceEditFormSubmit}
			>
				<div className={clientListStyles.transactionDetailsformContent}>
					<Row className="mb-1">
						<Col span={12}>
							<b>Revenue Sources</b>
						</Col>
						<Col span={12}>
							<Form.Item noStyle name={'sources'}>
								<Select
									mode="multiple"
									showArrow
									allowClear
									style={{ width: '100%' }}
									options={revSourceOptions}
									defaultValue={selectedData?.revSources?.map((item: any) => ({
										label: `${item.platform}-${item.name ? item.name : 'N/A'}-${
											item.currency
										}`,
										value: JSON.stringify({
											platform: item.platform,
											sourceId: item.sourceId,
										}),
									}))}
								/>
							</Form.Item>
						</Col>
					</Row>
					<Row className="mb-1">
						<Col span={12}>
							<b>Expected ROCE</b>
						</Col>
						<Col span={12}>
							{' '}
							<Input.Group compact>
								<Form.Item name="expectedROCE" noStyle>
									<InputNumber
										style={{ width: '85%' }}
										name="expectedROCE"
										placeholder="25"
										min={0}
										max={100}
										type={'number'}
										step={1}
									/>
								</Form.Item>
								<Form.Item noStyle>
									<Input value={'%'} disabled style={{ width: '15%' }} />
								</Form.Item>
							</Input.Group>
						</Col>
					</Row>
					<Row className="mb-1">
						<Col span={12}>
							<b>Expected IRR</b>
						</Col>
						<Col span={12}>
							{' '}
							<Input.Group compact>
								<Form.Item name="expectedIRR" noStyle>
									<InputNumber
										style={{ width: '85%' }}
										name="expectedIRR"
										placeholder="25"
										min={0}
										max={100}
										type={'number'}
									/>
								</Form.Item>
								<Form.Item noStyle>
									<Input value={'%'} disabled style={{ width: '15%' }} />
								</Form.Item>
							</Input.Group>
						</Col>
					</Row>
					<Row className="mb-1">
						<Col span={12}>
							<b>Expected Repayment</b>
						</Col>
						<Col span={12}>
							{' '}
							<Input.Group compact>
								<Form.Item name="currency" noStyle>
									<Input style={{ width: '20%' }} disabled size="small" />
								</Form.Item>
								<Form.Item
									name="expectedRepayment"
									noStyle
									rules={[
										{
											pattern: /^\d+(\.\d{1,2})?$/g,
											message: 'only 2 digits after decimal is accepted',
										},
									]}
								>
									<InputNumber
										style={{ width: '80%' }}
										placeholder="5000"
										size="small"
										formatter={(value) =>
											`${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
										}
									/>
								</Form.Item>
							</Input.Group>
						</Col>
					</Row>
					<Row className="mb-1">
						<Col span={12}>
							<b>Expected Revenue</b>
						</Col>
						<Col span={12}>
							{' '}
							<Input.Group compact>
								<Form.Item name="currency" noStyle>
									<Input style={{ width: '20%' }} disabled size="small" />
								</Form.Item>
								<Form.Item
									name="expectedRevenue"
									noStyle
									rules={[
										{
											pattern: /^\d+(\.\d{1,2})?$/g,
											message: 'Advance amount can be only digits',
										},
									]}
								>
									<InputNumber
										style={{ width: '80%' }}
										placeholder="5000"
										size="small"
										formatter={(value) =>
											`${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
										}
									/>
								</Form.Item>
							</Input.Group>
						</Col>
					</Row>
					<Row className="mb-1">
						<Col span={12}>
							<b>Risk Score</b>
						</Col>
						<Col span={12}>
							<Form.Item style={{ margin: '0px' }}>
								<Form.Item name="riskScore" noStyle>
									<InputNumber
										style={{ width: '100%' }}
										placeholder="75"
										size="small"
										min={0}
										max={100}
										formatter={(value) =>
											`${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
										}
									/>
								</Form.Item>
							</Form.Item>
						</Col>
					</Row>
					<Row>
						<Col span={12}>
							<b>Funding Source </b>
						</Col>
						<Col span={12}>
							<Form.Item name="fundingSource">
								<Select>
									<Option value="Equity">Equity</Option>
									<Option value="Avelinia">Avelinia</Option>
									<Option value="Naviter">Naviter</Option>
								</Select>
							</Form.Item>
						</Col>
					</Row>

					<Row>
						<Col span={12}>
							<b>Holiday Period (Days)</b>
						</Col>
						<Col span={12}>
							<Form.Item style={{ margin: '0px' }}>
								<Form.Item name="holidayPeriod" noStyle>
									<InputNumber
										style={{ width: '100%' }}
										placeholder="5"
										size="small"
										min={0}
									/>
								</Form.Item>
							</Form.Item>
						</Col>
					</Row>
					<Row className="mb-1">
						<Col span={12}>
							<b>Client Sign Contract Date </b>
						</Col>
						<Col span={12}>
							<Form.Item name="clientSignContractDate" noStyle>
								<DatePicker
									format={'DD-MM-YYYY'}
									style={{ width: '100%' }}
									placeholder="01-01-2022"
									size="small"
								/>
							</Form.Item>
						</Col>
					</Row>
					<Row className="mb-1">
						<Col span={12}>
							<b>Mandate Signing Date </b>
						</Col>
						<Col span={12}>
							<Form.Item name="mandateSignedDate" noStyle>
								<DatePicker
									format={'DD-MM-YYYY'}
									style={{ width: '100%' }}
									placeholder="01-01-2022"
									size="small"
								/>
							</Form.Item>
						</Col>
					</Row>
					<Row className="mb-1">
						<Col span={12}>
							<b>Trial Paid Out Date </b>
						</Col>
						<Col span={12}>
							<Form.Item name="trialPaidOutDate" noStyle>
								<DatePicker
									format={'DD-MM-YYYY'}
									style={{ width: '100%' }}
									placeholder="01-01-2022"
									size="small"
								/>
							</Form.Item>
						</Col>
					</Row>
					<Row className="mb-1">
						<Col span={12}>
							<b>First Drawdown Date</b>
						</Col>
						<Col span={12}>
							<Form.Item name="firstDrawdownDate" noStyle>
								<DatePicker
									format={'DD-MM-YYYY'}
									style={{ width: '100%' }}
									placeholder="01-01-2022"
									size="small"
								/>
							</Form.Item>
						</Col>
					</Row>
					<Row className="mb-1">
						<Col span={12}>
							<b>Funds Received Date</b>
						</Col>
						<Col span={12}>
							<Form.Item name="fundsReceivedDate" noStyle>
								<DatePicker
									format={'DD-MM-YYYY'}
									style={{ width: '100%' }}
									placeholder="01-01-2022"
									size="small"
								/>
							</Form.Item>
						</Col>
					</Row>
					<Row className="mb-1">
						<Col span={12}>
							<b>Payment Start Date </b>
						</Col>
						<Col span={12}>
							{/* <Form.Item style={{ margin: '0px' }}> */}
							<Form.Item name="paymentStartDate" noStyle>
								<DatePicker
									format={'DD-MM-YYYY'}
									style={{ width: '100%' }}
									placeholder="01-01-2022"
									size="small"
								/>
							</Form.Item>
							{/* </Form.Item> */}
						</Col>
					</Row>
					<Row>
						<Col span={12}>
							<b>Fulfilled Date </b>
						</Col>
						<Col span={12}>
							<Form.Item name="fulfilledDate" noStyle>
								<DatePicker
									format={'DD-MM-YYYY'}
									style={{ width: '100%' }}
									placeholder="01-01-2022"
									size="small"
								/>
							</Form.Item>
						</Col>
					</Row>
					<Row>
						<Col span={12}>
							<b>Upfront Fee </b>
						</Col>
						<Col span={12}>
							<Form.Item style={{ margin: '0px' }}>
								<Form.Item
									valuePropName="checked"
									name="upfrontFee"
									fieldKey={['upfrontFee']}
									noStyle
								>
									<Checkbox name="upfrontFee" />
								</Form.Item>
							</Form.Item>
						</Col>
					</Row>
					<Row>
						<Col span={12}>
							<b>Test </b>
						</Col>
						<Col span={12}>
							<Form.Item style={{ margin: '0px' }}>
								<Form.Item
									valuePropName="checked"
									name="isTest"
									fieldKey={['isTest']}
									noStyle
								>
									<Checkbox name="isTest" />
								</Form.Item>
							</Form.Item>
						</Col>
					</Row>
					<Row>
						<Col span={24}>Notes</Col>
						<Col span={24}>
							<Form.Item name="note">
								<TextArea name="note" rows={4} showCount maxLength={1000} />
							</Form.Item>
						</Col>
					</Row>
					<br />
					<Row justify="center">
						<Button className="_form_submit_small" type="primary" htmlType="submit">
							Confirm
						</Button>
					</Row>
				</div>
			</Form>
		</Modal>
	);
};

export default EditAdvanceMetaData;
