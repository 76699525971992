export const currencyList = [
	{ cid: 1, currency: 'AUD' },
	{ cid: 2, currency: 'CAD' },
	{ cid: 3, currency: 'CHF' },
	{ cid: 4, currency: 'DKK' },
	{ cid: 5, currency: 'EUR' },
	{ cid: 6, currency: 'GBP' },
	{ cid: 7, currency: 'HKD' },
	{ cid: 8, currency: 'HRV' },
	{ cid: 9, currency: 'NOK' },
	{ cid: 10, currency: 'SEK' },
	{ cid: 11, currency: 'USD' },
];
