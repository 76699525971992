/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useEffect, useState } from 'react';
import {
	Row,
	Col,
	Card,
	Button,
	Select,
	Divider,
	Modal,
	Form,
	Tag,
	Timeline,
	message,
} from 'antd';
import AdvanceCardStyles from '../../design/scss/advanceCards.module.scss';
import getSymbolFromCurrency from 'currency-symbol-map';
import {
	getAdvanceFullData,
	getActiveAdvanceFullData,
	updateAdminAdvance,
	recalculateAdvance,
	getAdvanceHistoricalLogs,
} from 'services/dashboard';
import { connect } from 'react-redux';
import clientListStyles from '../../design/scss/clientRepaymentsTable.module.scss';
import { useForm } from 'antd/lib/form/Form';
import { ClientInfo } from '../ClientInfoProps/clientInfo';
import { RootState } from '../../dto/redux.action.dto';
import moment from 'moment-timezone';
moment.tz.setDefault('Europe/London');
import LogsCards from './logsCards';
import { Role } from 'dto/admin.client.dto';
import AdvanceControllerPermission from 'dto/permissions/advance.permission';
import SystemLogControllerPermission from 'dto/permissions/system-log.permission';
import AdminControllerPermission from 'dto/permissions/admin.permission';
import EditAdvanceAdmin from 'components/EditAdvanceModal/EditAdvanceAdmin';
import { CheckCircleTwoTone, CloseCircleTwoTone } from '@ant-design/icons';
import EditAdvanceMetaData from 'components/EditAdvanceModal/EditAdvanceMetaData';
import EditAdvance from 'components/EditAdvanceModal/EditAdvance';
import HistoricalLogsCard from './historicalLogsCard';

const { Option } = Select;

interface Props {
	currency: string;
	clientInfo: ClientInfo;
	role: Role;
	level: string;
	clientId: string | null;
}

const AdvanceCard: React.FC<Props> = ({
	currency,
	clientInfo,
	role,
	level,
}) => {
	// update advance modal
	const [isUpdateAdvanceModalVisible, setIsUpdateAdvanceModalVisible] =
		useState(false);

	const [isLogsModalVisible, setLogsModalVisible] = useState(false);
	const [isHistoricalLogsModalVisible, setHistoricalLogsModalVisible] =
		useState(false);

	const [advance, setAdvance] = useState<any>(null);
	const [activeAdvance, setActiveAdvance] = useState<any>(null);
	const [advanceHistoricalLogs, setAdvanceHistoricalLogs] = useState<any>(null);

	const [totalAmountAdvanced, setTotalAmountAdvanced] = useState<number>(0);
	const [currentOutstanding, setCurrentOutstanding] = useState<number>(0);
	const [totalRepayments, setTotalRepayments] = useState<number>(0);
	const [totalRepaymentsProjection, setTotalRepaymentsProjetion] =
		useState<number>(0);

	const [selectedAdvance, setSelectAdvance] = useState(null);
	const [form] = useForm();
	const [advanceEditForm] = useForm();
	const [showAllonfo, setShowAllInfo] = useState<boolean>(false);
	const [showMeta, setShowMeta] = useState<boolean>(false);

	useEffect(() => {
		if (Array.isArray(advance) && advance?.length > 0) {
			form.setFieldsValue({
				selectAdvacne: advance && advance[0].advanceId,
			});
		}
	}, [advance]);

	useEffect(() => {
		if (selectedAdvance !== null) {
			const body = selectedAdvance;
			delete body.note;
			advanceEditForm.setFieldsValue({
				...body,
				initialBalance: selectedAdvance.initialBalance / 100,
				fee: selectedAdvance.fee / 100,
				currency: selectedAdvance?.currency
					? getSymbolFromCurrency(selectedAdvance?.currency)
					: '',
				status: selectedAdvance.status,
				totalRepaid: selectedAdvance.totalRepaid / 100,
				totalAmountOutstanding: selectedAdvance.totalAmountOutstanding / 100,
				totalRepaidProjection: selectedAdvance.totalRepaidProjection / 100,
				repaymentRate: selectedAdvance.repaymentRate,
				repaymentFrequency: selectedAdvance?.repaymentFrequency,
				minRepayment: selectedAdvance?.minRepayment
					? selectedAdvance?.minRepayment / 100
					: null,
				maxRepayment: selectedAdvance?.maxRepayment
					? selectedAdvance?.maxRepayment / 100
					: null,
				expectedRepayment: selectedAdvance?.expectedRepayment
					? selectedAdvance?.expectedRepayment / 100
					: null,
				expectedRevenue: selectedAdvance?.expectedRevenue
					? selectedAdvance?.expectedRevenue / 100
					: null,
				riskScore: selectedAdvance?.riskScore,
				holidayPeriod: selectedAdvance?.holidayPeriod,
				firstDrawdownDate: selectedAdvance?.firstDrawdownDate
					? moment(selectedAdvance?.firstDrawdownDate)
					: null,
				fundsReceivedDate: selectedAdvance?.fundsReceivedDate
					? moment(selectedAdvance?.fundsReceivedDate)
					: null,
				clientSignContractDate: selectedAdvance?.clientSignContractDate
					? moment(selectedAdvance?.clientSignContractDate)
					: null,
				activationDate: selectedAdvance?.activationDate
					? moment(selectedAdvance?.activationDate)
					: null,
				mandateSignedDate: selectedAdvance?.mandateSignedDate
					? moment(selectedAdvance?.mandateSignedDate)
					: null,
				trialPaidOutDate: selectedAdvance?.trialPaidOutDate
					? moment(selectedAdvance?.trialPaidOutDate)
					: null,
				fulfilledDate: selectedAdvance?.fulfilledDate
					? moment(selectedAdvance?.fulfilledDate)
					: null,
				paymentStartDate: selectedAdvance?.paymentStartDate
					? moment(selectedAdvance?.paymentStartDate)
					: null,
				upfrontFee: selectedAdvance?.upfrontFee,
				isTest: selectedAdvance?.isTest,
				expectedIRR: selectedAdvance?.expectedIRR
					? selectedAdvance?.expectedIRR * 100
					: null,
				expectedROCE: selectedAdvance?.expectedROCE
					? selectedAdvance?.expectedROCE * 100
					: null,
				sources: selectedAdvance?.revSources?.map((item: any) =>
					JSON.stringify({
						platform: item.platform,
						sourceId: item.sourceId,
					})
				),
				// sources: selectedAdvance?.revSources?.map((item: any) => ({
				// 	label: `${item.platform}-${item.name ? item.name : 'N/A'}-${
				// 		item.currency
				// 	}`,
				// 	value: JSON.stringify({
				// 		platform: item.platform,
				// 		sourceId: item.sourceId,
				// 	}),
				// })),
			});
		}
	}, [selectedAdvance]);

	useEffect(() => {
		if (selectedAdvance?.advanceId) {
			fetchAdvanceHistoricalLogs(selectedAdvance?.advanceId);
		}
	}, [selectedAdvance]);

	const setStatusStyle = (value: string | null) => {
		let color = value?.length > 5 ? 'geekblue' : 'green';
		if (value === 'APPROVED') {
			color = 'green';
		} else {
			color = 'volcano';
		}

		return (
			<>
				<Tag color={color} key={value} className={clientListStyles?.sourceButton}>
					{value?.toUpperCase()}
				</Tag>
			</>
		);
	};

	const showModal = () => {
		setIsUpdateAdvanceModalVisible(true);
	};

	const handleCancel = () => {
		setIsUpdateAdvanceModalVisible(false);
	};

	const showLogsModal = () => {
		setLogsModalVisible(true);
	};

	const logsHandleCancel = () => {
		setLogsModalVisible(false);
	};

	// const handleFormSubmit = async (values: {
	// 	repaymentRate: string;
	// 	sources: any[];
	// }) => {
	// 	const newSources = values.sources?.map((source: any) => JSON.parse(source));

	// 	const data = {
	// 		...values,
	// 		repaymentRate: parseFloat(values.repaymentRate),
	// 		sources: newSources,
	// 	};
	// 	await updateAdvanceData(selectedAdvance.advanceId, data);
	// 	setIsUpdateAdvanceModalVisible(false);
	// 	getAdvanceData();
	// };

	const setAdvanceSummary = (activeAdvance: any[]) => {
		let totalAmountAdvanced = 0,
			currentOutstanding = 0,
			totalRepayments = 0;
		let totalRepaymentProjection = 0;
		for (let i = 0; i < activeAdvance.length; i++) {
			totalAmountAdvanced +=
				activeAdvance[i].initialBalance + activeAdvance[i].fee;
			currentOutstanding += activeAdvance[i].totalAmountOutstanding;
			totalRepayments += activeAdvance[i].totalRepaid;
			totalRepaymentProjection += activeAdvance[i].totalRepaidProjection;
		}
		setTotalAmountAdvanced(totalAmountAdvanced / 100);
		setCurrentOutstanding(currentOutstanding / 100);
		setTotalRepayments(totalRepayments / 100);
		setTotalRepaymentsProjetion(totalRepaymentProjection / 100);
	};

	const getAdvanceData = async () => {
		const data = await getAdvanceFullData();
		const activeAdvanceData = await getActiveAdvanceFullData();

		setAdvance(data);
		setActiveAdvance(activeAdvanceData);
		setAdvanceSummary(activeAdvanceData);

		if (data.length > 0 && data !== null) {
			setSelectAdvance(data[0]);
		} else {
			setSelectAdvance(null);
		}
	};

	const fetchAdvanceHistoricalLogs = async (advanceId: string) => {
		const historicalLogs = await getAdvanceHistoricalLogs(advanceId);
		setAdvanceHistoricalLogs(historicalLogs);
	};

	// const [revSources, setRevSources] = useState([]);
	// const getRevSourceData = async (id: string) => {
	// 	const data = await getRevenueSourceInfo(id);
	// 	setRevSources(data);
	// };

	// const revSourceOptions = revSources?.map((SourceObject: any) => {
	// 	return {
	// 		label: `${SourceObject.platform}-${
	// 			SourceObject.name ? SourceObject.name : 'N/A'
	// 		}-${SourceObject.currency}`,

	// 		value: JSON.stringify({
	// 			platform: SourceObject.platform,
	// 			sourceId: SourceObject.sourceId,
	// 		}),
	// 	};
	// });

	useEffect(() => {
		getAdvanceData();
		// getRevSourceData(clientInfo.clientId);
	}, [clientInfo]);

	const dataLogs = selectedAdvance?.logs;

	const handleAdminEditSubmit = async (values: any) => {
		const body = {
			// ...values,
			advanceId: String(selectedAdvance.advanceId),
			repaymentRate: parseFloat(values.repaymentRate),
			goCardlessMandate: values?.goCardlessMandate
				? values?.goCardlessMandate
				: null,
			status: values.status,
			repaymentFrequency: values.repaymentFrequency,
			fee: values.fee,
			initialBalance: values.initialBalance,
			totalRepaid: values.totalRepaid,
			totalAmountOutstanding: values.totalAmountOutstanding,
			totalRepaidProjection: values.totalRepaidProjection,
			minRepayment: values?.minRepayment ? values?.minRepayment : null,
			maxRepayment: values?.maxRepayment ? values?.maxRepayment : null,
		};
		try {
			const updatedAdvance = await updateAdminAdvance(body);
			setSelectAdvance(updatedAdvance);
		} catch (err) {
			message.error('Update Failed');
		}
	};

	const handleBasicEditSubmit = async (values: any) => {
		const body = {
			// ...values,
			advanceId: String(selectedAdvance.advanceId),

			status: values.status,
			// repaymentFrequency: values.repaymentFrequency,
			fee: values.fee,
			initialBalance: values.initialBalance,
		};
		try {
			const updatedAdvance = await updateAdminAdvance(body);
			setSelectAdvance(updatedAdvance);
		} catch (err) {
			message.error('Update Failed');
		}
	};

	const handleMetaDataEditSubmit = async (values: any) => {
		const newSources =
			values.sources && values.sources.map((source: any) => JSON.parse(source));
		const body = {
			...values,
			advanceId: String(selectedAdvance.advanceId),
			expectedIRR: values?.expectedIRR
				? Number((values?.expectedIRR / 100).toFixed(2))
				: null,
			expectedROCE: values?.expectedROCE
				? Number((values?.expectedROCE / 100).toFixed(2))
				: null,
			holidayPeriod: values?.holidayPeriod ? values?.holidayPeriod : 0,
			sources: newSources ? newSources : [],
		};
		try {
			delete body.currency;
			delete body.repaymentRate;
			const updatedAdvance = await updateAdminAdvance(body);
			setSelectAdvance(updatedAdvance);
		} catch (err) {
			message.error('Update Failed');
		}
	};

	const handleRecalculateAdvanceSubmit = async () => {
		const { advanceId } = selectedAdvance;
		await recalculateAdvance(advanceId);
	};

	return (
		<div>
			{level === AdminControllerPermission.SUPER_ADMIN ? (
				<EditAdvanceAdmin
					isAdvanceEditModalVisible={isUpdateAdvanceModalVisible}
					handleAdvanceEditCancel={handleCancel}
					selectedData={selectedAdvance}
					editForm={advanceEditForm}
					handleAdvanceEditFormSubmit={handleAdminEditSubmit}
				/>
			) : (
				// <Modal
				// 	title={
				// 		<Row align="middle">
				// 			<h3 style={{ margin: '16px 16px 16px 0px' }}>Update Advance</h3>
				// 		</Row>
				// 	}
				// 	visible={isUpdateAdvanceModalVisible}
				// 	onCancel={handleCancel}
				// 	footer={null}
				// >
				// 	<div>
				// 		<Col>
				// 			<h4 style={{ margin: 0 }}>
				// 				{selectedAdvance !== null && selectedAdvance.advanceId}
				// 			</h4>
				// 		</Col>
				// 		<Col>
				// 			<Row className={AdvanceCardStyles.Clientmodal_spanContainingRow}>
				// 				<span>
				// 					<b>ID:</b>
				// 				</span>
				// 				<span>{clientInfo.clientId}</span>
				// 			</Row>
				// 		</Col>
				// 	</div>
				// 	<br />
				// 	<div className={AdvanceCardStyles.Clientmodal_spanContainingRow_new}>
				// 		<Row>
				// 			<Col span={10}>
				// 				<b>Current Advance Amount</b>
				// 			</Col>
				// 			<Col span={10}>
				// 				{getSymbolFromCurrency(currency)}
				// 				{selectedAdvance !== null &&
				// 					String(
				// 						selectedAdvance.initialBalance / 100 + selectedAdvance.fee / 100
				// 					).replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
				// 			</Col>
				// 		</Row>

				// 		<Row>
				// 			<Col span={10}>
				// 				<b>Repayment Rate</b>
				// 			</Col>
				// 			<Col span={10}>
				// 				{selectedAdvance !== null && selectedAdvance.repaymentRate}%
				// 			</Col>
				// 		</Row>

				// 		<Row>
				// 			<Col span={10}>
				// 				<b>Fee</b>
				// 			</Col>
				// 			<Col span={10}>{`${getSymbolFromCurrency(currency)} ${
				// 				selectedAdvance !== null &&
				// 				String(selectedAdvance.fee / 100).replace(/\B(?=(\d{3})+(?!\d))/g, ',')
				// 			}`}</Col>
				// 		</Row>
				// 	</div>
				// 	<br />
				// 	<Form
				// 		name="basic"
				// 		layout="vertical"
				// 		requiredMark={false}
				// 		onFinish={handleFormSubmit}
				// 		form={advanceEditForm}
				// 	>
				// 		<Form.Item label="Repayment Gateway" name="repaymentGateway">
				// 			<Input
				// 				name="repaymentGateway"
				// 				defaultValue={
				// 					selectedAdvance && selectedAdvance.repaymentGateway
				// 						? selectedAdvance.repaymentGateway
				// 						: ' '
				// 				}
				// 				disabled
				// 			/>
				// 		</Form.Item>

				// 		<Form.Item label="Status" name="status">
				// 			<Select
				// 				placeholder="Please Select"
				// 				disabled={
				// 					!role?.permissions?.includes(
				// 						AdvanceControllerPermission.UpdateAdvanceStatus
				// 					) && level !== AdminControllerPermission.SUPER_ADMIN
				// 				}
				// 			>
				// 				<Option value="APPROVED">APPROVED</Option>
				// 				<Option value="PENDING">PENDING</Option>
				// 				<Option value="DECLIENED">DECLIENED</Option>
				// 				<Option value="FULFILLED">FULFILLED</Option>
				// 			</Select>
				// 		</Form.Item>

				// 		<Form.Item label="Select Currency" name="currency">
				// 			<Select>
				// 				{currencyList.map((data: { cid: number; currency: string }) => (
				// 					<Option value={data.currency} key={data.cid}>
				// 						{data.currency}({getSymbolFromCurrency(data.currency)})
				// 					</Option>
				// 				))}
				// 			</Select>
				// 		</Form.Item>

				// 		<Form.Item label="Revenue Sources" name="sources">
				// 			<Select
				// 				mode="multiple"
				// 				showArrow
				// 				style={{ width: '100%' }}
				// 				options={revSourceOptions}
				// 			/>
				// 		</Form.Item>

				// 		<div style={{ margin: '0px 0px 20px', display: 'flex' }}>
				// 			{selectedAdvance?.revSources.map((item: any) => {
				// 				return (
				// 					<span style={{ color: '#20347b', paddingRight: '20px' }}>
				// 						{`${item.platform}-${item.name ? item.name : 'N/A'}-${item.currency}`}
				// 					</span>
				// 				);
				// 			})}
				// 		</div>

				// 		<Form.Item label="Amount">
				// 			<Input.Group compact>
				// 				<Form.Item name="currency" noStyle>
				// 					<Input style={{ width: '20%' }} disabled />
				// 				</Form.Item>
				// 				<Form.Item
				// 					name="initialBalance"
				// 					noStyle
				// 					rules={[
				// 						{
				// 							required: true,
				// 							message: 'Please input your requested advance amount',
				// 						},
				// 						{
				// 							pattern: /^\d+(\.\d{1,2})?$/g,
				// 							message: 'only 2 digits after decimal is accepted',
				// 						},
				// 					]}
				// 				>
				// 					<InputNumber
				// 						style={{ width: '80%' }}
				// 						formatter={(value: string | number) =>
				// 							`${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
				// 						}
				// 					/>
				// 				</Form.Item>
				// 			</Input.Group>
				// 		</Form.Item>
				// 		<Form.Item label="Rate">
				// 			<Form.Item
				// 				name="repaymentRate"
				// 				noStyle
				// 				rules={[
				// 					{
				// 						required: true,
				// 						message: 'Please input this field!',
				// 					},
				// 					{
				// 						pattern: /^\d+(\.\d{1,2})?$/g,
				// 						message: 'only 2 digits after decimal is accepted',
				// 					},
				// 				]}
				// 			>
				// 				<InputNumber
				// 					step={1}
				// 					style={{ width: '100%' }}
				// 					min={0}
				// 					max={100}
				// 					formatter={(rate) => `${rate}%`}
				// 				/>
				// 			</Form.Item>
				// 		</Form.Item>
				// 		<Form.Item label="Fee">
				// 			<Input.Group compact>
				// 				<Form.Item name="currency" noStyle>
				// 					<Input style={{ width: '20%' }} name="currency" disabled />
				// 				</Form.Item>
				// 				<Form.Item
				// 					name="fee"
				// 					noStyle
				// 					rules={[
				// 						{
				// 							required: true,
				// 							message: 'Please input your fee amount',
				// 						},
				// 						{
				// 							pattern: /^\d+(\.\d{1,2})?$/g,
				// 							message: 'only 2 digits after decimal is accepted',
				// 						},
				// 					]}
				// 				>
				// 					<InputNumber
				// 						style={{ width: '80%' }}
				// 						name="amount"
				// 						placeholder="500,000"
				// 						formatter={(value: string | number) =>
				// 							`${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
				// 						}
				// 					/>
				// 				</Form.Item>
				// 			</Input.Group>
				// 		</Form.Item>
				// 		<Form.Item label="Note" name="note">
				// 			<TextArea name="notes" rows={4} showCount maxLength={1000} />
				// 		</Form.Item>
				// 		<Row justify="center">
				// 			<Button className="_form_submit_small" type="primary" htmlType="submit">
				// 				Update
				// 			</Button>
				// 		</Row>
				// 	</Form>
				// </Modal>
				<EditAdvance
					isAdvanceEditModalVisible={isUpdateAdvanceModalVisible}
					handleAdvanceEditCancel={handleCancel}
					selectedData={selectedAdvance}
					editForm={advanceEditForm}
					handleAdvanceEditFormSubmit={handleBasicEditSubmit}
				/>
			)}

			<Modal
				title={
					<Row align="middle">
						<h3 style={{ margin: '16px 16px 16px 0px' }}>
							Logs Details{' '}
							<span style={{ fontSize: '20px' }}>
								Advance ID: {selectedAdvance != null ? selectedAdvance.advanceId : ''}
							</span>{' '}
						</h3>
					</Row>
				}
				width={1200}
				centered
				visible={isLogsModalVisible}
				onCancel={logsHandleCancel}
				footer={null}
			>
				{selectedAdvance !== null && (
					<p>
						<LogsCards logsData={dataLogs} />
					</p>
				)}
			</Modal>
			<Modal
				title={
					<Row align="middle">
						<h3 style={{ margin: '16px 16px 16px 0px' }}>
							Historical Logs{' '}
							<span style={{ fontSize: '16px' }}>
								Advance ID: {selectedAdvance != null ? selectedAdvance.advanceId : ''}
							</span>{' '}
						</h3>
					</Row>
				}
				width={1200}
				centered
				visible={isHistoricalLogsModalVisible}
				onCancel={() => setHistoricalLogsModalVisible(false)}
				footer={null}
			>
				{selectedAdvance !== null && advanceHistoricalLogs !== null && (
					<p>
						<HistoricalLogsCard logsData={advanceHistoricalLogs.docs} />
					</p>
				)}
			</Modal>
			<EditAdvanceMetaData
				isAdvanceEditModalVisible={showMeta}
				handleAdvanceEditCancel={() => setShowMeta(false)}
				selectedData={selectedAdvance}
				editForm={advanceEditForm}
				handleAdvanceEditFormSubmit={handleMetaDataEditSubmit}
			/>
			<Card bordered={false}>
				<Row justify="space-between" align="middle">
					<h3>Advances</h3>
				</Row>
				<div className={AdvanceCardStyles.advance_infoCard}>
					<Row>
						<Col span={12} className={AdvanceCardStyles.advance_title}>
							No. of Advances Issued
						</Col>
						<Col span={12} className={AdvanceCardStyles.advance_text}>
							{activeAdvance ? activeAdvance.length : 0}
						</Col>
					</Row>
					<Row>
						<Col span={12} className={AdvanceCardStyles.advance_title}>
							Total Amount Advanced
						</Col>
						<Col span={12} className={AdvanceCardStyles.advance_text}>
							{`${getSymbolFromCurrency(currency)}`}
							{` `}
							{`${totalAmountAdvanced}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
						</Col>
					</Row>
					<Row>
						<Col span={12} className={AdvanceCardStyles.advance_title}>
							Current Outstanding Balance
						</Col>
						<Col span={12} className={AdvanceCardStyles.advance_text}>
							{`${getSymbolFromCurrency(currency)}`}
							{` `}
							{`${currentOutstanding}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
						</Col>
					</Row>
					<Row>
						<Col span={12} className={AdvanceCardStyles.advance_title}>
							Total Repayments Projection{' '}
						</Col>
						<Col span={12} className={AdvanceCardStyles.advance_text}>
							{`${getSymbolFromCurrency(currency)}`}
							{` `}
							{`${totalRepaymentsProjection}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
						</Col>
					</Row>
					<Row>
						<Col span={12} className={AdvanceCardStyles.advance_title}>
							Total Repaid
						</Col>
						<Col span={12} className={AdvanceCardStyles.advance_text}>
							{`${getSymbolFromCurrency(currency)}`}
							{` `}
							{`${totalRepayments}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
						</Col>
					</Row>
				</div>
				<br />
				{selectedAdvance !== null && (
					<div className={AdvanceCardStyles.infoContainer}>
						<Row align="middle" justify="space-between">
							<Col span={12}>
								<h4 style={{ margin: 0 }}>Advance ID:{selectedAdvance?.advanceId}</h4>
								<Row justify="space-between" align="middle" style={{ width: '60%' }}>
									{selectedAdvance !== null
										? setStatusStyle(selectedAdvance?.status)
										: ''}
									{selectedAdvance !== null ? (
										<Tag
											color={'grey'}
											key={selectedAdvance?.repaymentFrequency}
											className={clientListStyles?.sourceButton}
										>
											{selectedAdvance?.repaymentFrequency?.toUpperCase()}
										</Tag>
									) : (
										''
									)}
								</Row>
							</Col>
							<Col span={12}>
								<Row justify="end">
									<Form form={form}>
										<Form.Item noStyle name="selectAdvacne">
											<Select
												style={{ width: 160 }}
												onChange={(value: any) => {
													const filter = advance.filter(
														(data: any) => data?.advanceId === value
													);
													setSelectAdvance(filter[0]);
												}}
											>
												{advance ? (
													advance.map((info: any) => (
														<Option value={info.advanceId}>{info.advanceId}</Option>
													))
												) : (
													<Option value="option 2" disabled>
														No Advance available
													</Option>
												)}
											</Select>
										</Form.Item>
									</Form>
								</Row>
							</Col>
						</Row>
						<>
							<Row justify="space-between">
								<span className="flex">
									<div>
										<span className={AdvanceCardStyles.titel}>Created On: </span>
										<span className={AdvanceCardStyles.text}>
											{selectedAdvance !== null &&
												moment(selectedAdvance.createdAt).format('LL')}
										</span>
									</div>
									<div>
										<span className={AdvanceCardStyles.titel}>Updated On:</span>
										<span className={AdvanceCardStyles.text}>
											{selectedAdvance !== null &&
												moment(selectedAdvance.updatedAt).format('LL')}
										</span>
									</div>
								</span>
								<Button
									className={AdvanceCardStyles.gutterBox}
									onClick={handleRecalculateAdvanceSubmit}
									type="primary"
								>
									Recalculate Advance
								</Button>
							</Row>
							<Divider />
							<Row>
								<Col span={12} className={AdvanceCardStyles.NewTitle}>
									Activation Date
								</Col>
								<Col span={12} className={AdvanceCardStyles.NewText}>
									{selectedAdvance !== null &&
									selectedAdvance.activationDate &&
									selectedAdvance.activationDate !== null
										? moment(selectedAdvance.activationDate).format('YYYY-MM-DD')
										: 'N/A'}
								</Col>
							</Row>
							<Row>
								<Col span={12} className={AdvanceCardStyles.NewTitle}>
									Repayment Rate
								</Col>
								<Col span={12} className={AdvanceCardStyles.NewText}>
									{selectedAdvance !== null && selectedAdvance.repaymentRate}%
								</Col>
							</Row>
							<Row>
								<Col span={12} className={AdvanceCardStyles.NewTitle}>
									Amount
								</Col>
								<Col span={12} className={AdvanceCardStyles.NewText}>
									{selectedAdvance !== null &&
										`${
											selectedAdvance?.currency
												? getSymbolFromCurrency(selectedAdvance?.currency)
												: getSymbolFromCurrency(currency)
										} ${String(selectedAdvance.initialBalance / 100).replace(
											/\B(?=(\d{3})+(?!\d))/g,
											','
										)}`}
								</Col>
							</Row>
							<Row>
								<Col span={12} className={AdvanceCardStyles.NewTitle}>
									Fee
								</Col>
								<Col span={12} className={AdvanceCardStyles.NewText}>
									{selectedAdvance !== null &&
										`${
											selectedAdvance?.currency
												? getSymbolFromCurrency(selectedAdvance?.currency)
												: getSymbolFromCurrency(currency)
										} ${String(selectedAdvance.fee / 100).replace(
											/\B(?=(\d{3})+(?!\d))/g,
											','
										)}`}
								</Col>
							</Row>
							<Row>
								<Col span={12} className={AdvanceCardStyles.NewTitle}>
									Total to Repay
								</Col>
								<Col span={12} className={AdvanceCardStyles.NewText}>
									{selectedAdvance !== null &&
										`${
											selectedAdvance?.currency
												? getSymbolFromCurrency(selectedAdvance?.currency)
												: getSymbolFromCurrency(currency)
										} ${String(
											(selectedAdvance?.fee + selectedAdvance?.initialBalance) / 100
										).replace(/\B(?=(\d{3})+(?!\d))/g, ',')}`}
								</Col>
							</Row>
							<Row>
								<Col span={12} className={AdvanceCardStyles.NewTitle}>
									Total Repayments Projection
								</Col>
								<Col span={12} className={AdvanceCardStyles.NewText}>
									{selectedAdvance !== null &&
										`${
											selectedAdvance?.currency
												? getSymbolFromCurrency(selectedAdvance?.currency)
												: getSymbolFromCurrency(currency)
										} ${String(selectedAdvance.totalRepaidProjection / 100).replace(
											/\B(?=(\d{3})+(?!\d))/g,
											','
										)}`}
								</Col>
							</Row>
							<Row>
								<Col span={12} className={AdvanceCardStyles.NewTitle}>
									Total to Repay Projection
								</Col>
								<Col span={12} className={AdvanceCardStyles.NewText}>
									{selectedAdvance !== null &&
										`${
											selectedAdvance?.currency
												? getSymbolFromCurrency(selectedAdvance?.currency)
												: getSymbolFromCurrency(currency)
										} ${String(
											(selectedAdvance?.fee +
												selectedAdvance?.initialBalance -
												selectedAdvance?.totalRepaidProjection) /
												100
										).replace(/\B(?=(\d{3})+(?!\d))/g, ',')}`}
								</Col>
							</Row>
							<Row>
								<Col span={12} className={AdvanceCardStyles.NewTitle}>
									Offer ID
								</Col>
								<Col span={12} className={AdvanceCardStyles.NewText}>
									{selectedAdvance?.offerId}
								</Col>
							</Row>
							<Row>
								<Col span={12} className={AdvanceCardStyles.NewTitle}>
									Total Repaid
								</Col>
								<Col span={12} className={AdvanceCardStyles.NewText}>
									{selectedAdvance !== null &&
										`${
											selectedAdvance?.currency
												? getSymbolFromCurrency(selectedAdvance?.currency)
												: getSymbolFromCurrency(currency)
										} ${String(selectedAdvance.totalRepaid / 100).replace(
											/\B(?=(\d{3})+(?!\d))/g,
											','
										)}`}
								</Col>
							</Row>
							<Row>
								<Col span={12} className={AdvanceCardStyles.NewTitle}>
									Total to be repaid{' '}
								</Col>
								<Col span={12} className={AdvanceCardStyles.NewText}>
									{selectedAdvance !== null &&
										`${
											selectedAdvance?.currency
												? getSymbolFromCurrency(selectedAdvance?.currency)
												: getSymbolFromCurrency(currency)
										} ${String(selectedAdvance.totalAmountOutstanding / 100).replace(
											/\B(?=(\d{3})+(?!\d))/g,
											','
										)}`}
								</Col>
							</Row>
							<Row>
								<Col span={12} className={AdvanceCardStyles.NewTitle}>
									Repayment Gateway
								</Col>
								<Col span={12} className={AdvanceCardStyles.NewText}>
									{selectedAdvance !== null && selectedAdvance?.repaymentGateway}
								</Col>
							</Row>
							<Row>
								<Col span={12} className={AdvanceCardStyles.NewTitle}>
									Holiday Period
								</Col>
								<Col span={12} className={AdvanceCardStyles.NewText}>
									{selectedAdvance !== null && selectedAdvance?.holidayPeriod
										? selectedAdvance?.holidayPeriod
										: 'N/A'}
								</Col>
							</Row>
							<Row>
								<Col span={12} className={AdvanceCardStyles.NewTitle}>
									Payment Start Date
								</Col>
								<Col span={12} className={AdvanceCardStyles.NewText}>
									{selectedAdvance !== null && selectedAdvance?.paymentStartDate
										? moment(selectedAdvance?.paymentStartDate).format('DD-MM-YYYY')
										: 'N/A'}
								</Col>
							</Row>
							<Row>
								<Col span={12} className={AdvanceCardStyles.NewTitle}>
									First Drawdown Date
								</Col>
								<Col span={12} className={AdvanceCardStyles.NewText}>
									{selectedAdvance !== null && selectedAdvance?.firstDrawdownDate
										? moment(selectedAdvance?.firstDrawdownDate).format('DD-MM-YYYY')
										: 'N/A'}
								</Col>
							</Row>

							{showAllonfo ? (
								<>
									{' '}
									<Row>
										<Col span={12} className={AdvanceCardStyles.NewTitle}>
											Minimum Repayment
										</Col>
										<Col span={12} className={AdvanceCardStyles.NewText}>
											{selectedAdvance !== null &&
												`${
													selectedAdvance?.currency
														? getSymbolFromCurrency(selectedAdvance?.currency)
														: getSymbolFromCurrency(currency)
												} ${String(selectedAdvance.minRepayment / 100).replace(
													/\B(?=(\d{3})+(?!\d))/g,
													','
												)}`}
										</Col>
									</Row>
									<Row>
										<Col span={12} className={AdvanceCardStyles.NewTitle}>
											Maximum Repayment
										</Col>
										<Col span={12} className={AdvanceCardStyles.NewText}>
											{selectedAdvance !== null &&
												`${
													selectedAdvance?.currency
														? getSymbolFromCurrency(selectedAdvance?.currency)
														: getSymbolFromCurrency(currency)
												} ${String(selectedAdvance.maxRepayment / 100).replace(
													/\B(?=(\d{3})+(?!\d))/g,
													','
												)}`}
										</Col>
									</Row>
									<Row>
										<Col span={12} className={AdvanceCardStyles.NewTitle}>
											Expected Repayment
										</Col>
										<Col span={12} className={AdvanceCardStyles.NewText}>
											{selectedAdvance !== null &&
												`${
													selectedAdvance?.currency
														? getSymbolFromCurrency(selectedAdvance?.currency)
														: getSymbolFromCurrency(currency)
												} ${String(selectedAdvance.expectedRepayment / 100).replace(
													/\B(?=(\d{3})+(?!\d))/g,
													','
												)}`}
										</Col>
									</Row>
									<Row>
										<Col span={12} className={AdvanceCardStyles.NewTitle}>
											Risk Score
										</Col>
										<Col span={12} className={AdvanceCardStyles.NewText}>
											{selectedAdvance !== null && selectedAdvance?.riskScore
												? selectedAdvance?.riskScore
												: 'N/A'}
										</Col>
									</Row>
									<Row>
										<Col span={12} className={AdvanceCardStyles.NewTitle}>
											Revenue Source
										</Col>
										<Col span={12} className={AdvanceCardStyles.NewText}>
											{selectedAdvance !== null && selectedAdvance?.revSources?.length > 0
												? selectedAdvance?.revSources?.map(
														(data: { platform: string; name: string }) => (
															<Tag color={data.platform === 'SHOPIFY' ? 'green' : 'blue'}>
																{data.platform} - {data.name ? data.name : 'N/A'}
															</Tag>
														)
												  )
												: 'N/A'}
										</Col>
									</Row>
									<Row>
										<Col span={12} className={AdvanceCardStyles.NewTitle}>
											Upfront Fee
										</Col>
										<Col span={12} className={AdvanceCardStyles.NewText}>
											{selectedAdvance !== null && selectedAdvance?.upfrontFee ? (
												<CheckCircleTwoTone twoToneColor="#52c41a" />
											) : (
												<CloseCircleTwoTone twoToneColor="#FF0000" />
											)}
										</Col>
									</Row>
									<Row>
										<Col span={12} className={AdvanceCardStyles.NewTitle}>
											Test
										</Col>
										<Col span={12} className={AdvanceCardStyles.NewText}>
											{selectedAdvance !== null && selectedAdvance?.isTest ? (
												<CheckCircleTwoTone twoToneColor="#52c41a" />
											) : (
												<CloseCircleTwoTone twoToneColor="#FF0000" />
											)}
										</Col>
									</Row>
									<Row>
										<Col span={12} className={AdvanceCardStyles.NewTitle}>
											Fulfilled Date
										</Col>
										<Col span={12} className={AdvanceCardStyles.NewText}>
											{selectedAdvance !== null && selectedAdvance?.fulfilledDate !== null
												? moment(selectedAdvance?.fulfilledDate).format('YYYY-MM-DD')
												: 'N/A'}
										</Col>
									</Row>
									<Row>
										<Col span={12} className={AdvanceCardStyles.NewTitle}>
											Name
										</Col>
										<Col span={12} className={AdvanceCardStyles.NewText}>
											{JSON?.parse(selectedAdvance?.offer?.offer).name}
										</Col>
									</Row>
									<Row>
										<Col span={12} className={AdvanceCardStyles.NewTitle}>
											Description
										</Col>
										<Col span={12} className={AdvanceCardStyles.NewText}>
											{JSON?.parse(selectedAdvance?.offer?.offer).description}
										</Col>
									</Row>
									{/* <Row>
										<Col span={12} className={AdvanceCardStyles.NewTitle}>
											Currency
										</Col>
										<Col span={12} className={AdvanceCardStyles.NewText}>
											{JSON?.parse(selectedAdvance?.offer?.offer).currency}
										</Col>
									</Row> */}
									<p style={{ cursor: 'pointer' }} onClick={() => setShowAllInfo(false)}>
										Show less...
									</p>
								</>
							) : (
								<p style={{ cursor: 'pointer' }} onClick={() => setShowAllInfo(true)}>
									More info...
								</p>
							)}

							<Row>
								<Col span={24}>
									<b>Notes</b>
								</Col>
								<Col span={24}>
									<div
										style={{
											height: '300px',
											width: '100%',
											overflow: 'auto',
											overflowX: 'hidden',
											padding: '10px 10px',
											border: '1px solid grey',
										}}
									>
										<Timeline mode={'left'}>
											{selectedAdvance.note &&
												Array.isArray(selectedAdvance.note) &&
												selectedAdvance.note.reverse().map((element: any) => {
													if (element.note && element.dateTime) {
														return (
															<Timeline.Item>
																{JSON.stringify(element.note)} -{' '}
																<i>added on {moment(element.dateTime).format('YYYY-MM-DD')}</i>
															</Timeline.Item>
														);
													} else {
														return <></>;
													}
												})}
										</Timeline>
									</div>
								</Col>
							</Row>
							<br />
							<Row
								gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}
								style={{ padding: '2rem' }}
							>
								{(role?.permissions?.includes(
									AdvanceControllerPermission.UpdateAdvance
								) ||
									level === AdminControllerPermission.SUPER_ADMIN) && (
									<>
										<Button
											className={AdvanceCardStyles.gutterBox}
											onClick={showModal}
											type="primary"
											disabled={
												level === AdminControllerPermission.SUPER_ADMIN
													? false
													: (selectedAdvance.status &&
															selectedAdvance.status === 'APPROVED') ||
													  selectedAdvance.status === 'FULFILLED'
											}
										>
											Update Advance
										</Button>
										{level === AdminControllerPermission.SUPER_ADMIN && (
											<Button
												className={AdvanceCardStyles.gutterBox}
												onClick={() => setShowMeta(true)}
												type="primary"
												disabled={
													level === AdminControllerPermission.SUPER_ADMIN
														? false
														: (selectedAdvance.status &&
																selectedAdvance.status === 'APPROVED') ||
														  selectedAdvance.status === 'FULFILLED'
												}
											>
												Update Metadata
											</Button>
										)}
									</>
								)}

								<Button
									disabled={
										selectedAdvance?.offer?.pandaDocClientLink?.length < 1 ||
										selectedAdvance?.offer?.pandaDocClientLink === undefined
									}
									className={AdvanceCardStyles.gutterBox}
									type="primary"
								>
									Download Contract
								</Button>
								{role?.permissions?.includes(
									SystemLogControllerPermission.SystemLogList
								) ||
									(level === AdminControllerPermission.SUPER_ADMIN && (
										<Button
											className={AdvanceCardStyles.gutterBox}
											type="primary"
											onClick={showLogsModal}
										>
											Logs
										</Button>
									))}
								{level === AdminControllerPermission.SUPER_ADMIN && (
									<Button
										className={AdvanceCardStyles.gutterBox}
										type="primary"
										onClick={() => setHistoricalLogsModalVisible(true)}
									>
										Historical Logs
									</Button>
								)}
							</Row>
						</>
					</div>
				)}
			</Card>
		</div>
	);
};

const mapStateToProps = (state: RootState) => ({
	currency: state.dash.clientData?.currency
		? state.dash.clientData.currency
		: 'NA',
	clientInfo: state.dash.clientData,
	clientId: state.dash.clientData.clientId,
	role: state.auth?.loggedInUser?.role,
	level: state.auth?.loggedInUser?.level,
});

export default connect(mapStateToProps)(AdvanceCard);
