import React from 'react';
import { Layout } from 'antd';
//main menu
import { MainMenu } from '..';
import navStyles from '../../design/scss/navbarComponents.module.scss';

const SideNav: React.FC = () => {
	return (
		<React.Fragment>
			<Layout.Sider className={navStyles.navbar_sideNav}>
				<MainMenu screen="large" />
			</Layout.Sider>
		</React.Fragment>
	);
};

export default SideNav;
