enum ConnectControllerPermission {
	ClientFacebookPageInfo = 'ClientFacebookPageInfo',
	ClientMandateInfo = 'ClientMandateInfo',
	ClientStripeAcctInfo = 'ClientStripeAcctInfo',
	ClientShopifyStoreInfo = 'ClientShopifyStoreInfo',
	ClientRevenueSourceList = 'ClientRevenueSourceList',
	UpdateClientFacebookPageInfo = 'UpdateClientFacebookPageInfo',
	UpdateClientMandateInfo = 'UpdateClientMandateInfo',
	UpdateClientShopifyInfo = 'UpdateClientShopifyInfo',
	UpdateClientStripeInfo = 'UpdateClientStripeInfo',
}

export default ConnectControllerPermission;
