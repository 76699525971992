/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { message } from 'antd';
import axios from 'axios';
import { BASE_URL } from 'config';
import { store } from 'store';
import { logOut } from 'store/actions/authActions';

const errorHandler = (err: any) => {
	if (err?.response?.status === 401) {
		logOut();
	}
	if (typeof err.response.data.message === 'string')
		return message.error(err.response.data.message);
	return err.response.data.message.map((e: string) => {
		message.error(e);
	});
};

//get client for client list initially
export const getClientList = async () => {
	const options = {
		headers: {
			Authorization: `Bearer ${store.getState().auth.accessToken}`,
			'Content-Type': 'application/json',
		},
	};
	try {
		const result = await axios.get(`${BASE_URL}client/list?limit=10`, options);
		return result;
	} catch (err) {
		return errorHandler(err);
	}
};

//pagination for client list
export const onClientPageChange = async (page: number, limit: number) => {
	const options = {
		headers: {
			Authorization: `Bearer ${store.getState().auth.accessToken}`,
			'Content-Type': 'application/json',
		},
	};
	try {
		const res = await axios.get(
			`${BASE_URL}client/list?limit=${limit}&page=${page}`,
			options
		);
		return res.data;
	} catch (err) {
		return errorHandler(err);
	}
};

//get Facebook Id for client list initially
export const getFacebookId = async (clientId: string) => {
	const options = {
		headers: {
			Authorization: `Bearer ${store.getState().auth.accessToken}`,
			'Content-Type': 'application/json',
		},
	};
	try {
		const { data } = await axios.get(
			`${BASE_URL}connect/facebook/${clientId}`,
			options
		);
		return data;
	} catch (err) {
		return errorHandler(err);
	}
};

//get GoodleAds Id for client list initially
export const getGoogleAds = async (clientId: string) => {
	const options = {
		headers: {
			Authorization: `Bearer ${store.getState().auth.accessToken}`,
			'Content-Type': 'application/json',
		},
	};
	try {
		const { data } = await axios.get(
			`${BASE_URL}connect/google/${clientId}`,
			options
		);
		return data;
	} catch (err) {
		return errorHandler(err);
	}
};

export const getClientStatus = async (clientId: string) => {
	const options = {
		headers: {
			Authorization: `Bearer ${store.getState().auth.accessToken}`,
			'Content-Type': 'application/json',
		},
	};
	try {
		const res = await axios.get(
			`${BASE_URL}client/status-info/${clientId}`,
			options
		);
		return res.data;
	} catch (err) {
		return errorHandler(err);
	}
};

export const editClientInfo = async (data: {
	companyName: string;
	regCountry: string;
	kyc: {
		firstName: string;
		lastName: string;
		companyName: string;
		businessRegNo: string;
	};
	phone: string;
	email: string;
}) => {
	const options = {
		headers: {
			Authorization: `Bearer ${store.getState().auth.accessToken}`,
			'Content-Type': 'application/json',
		},
	};
	try {
		const res = await axios.patch(
			`${BASE_URL}client/update/${store.getState().dash.clientData.clientId}`,
			data,
			options
		);
		message.success('Client Edited');
		return res.data;
	} catch (err) {
		return errorHandler(err);
	}
};

export const updateRepayments = async (data: any) => {
	const options = {
		headers: {
			Authorization: `Bearer ${store.getState().auth.accessToken}`,
			'Content-Type': 'application/json',
		},
	};
	if (
		data.goCardlessReqStatus === 'NOT_SENT' ||
		data.goCardlessReqStatus === 'SENT'
	) {
		// eslint-disable-next-line @typescript-eslint/no-unused-vars
		const { goCardlessReqStatus, ...body } = data;
		try {
			const res = await axios.patch(`${BASE_URL}revenue/update`, body, options);
			message.success('Repayment Updated');
			return res.data;
		} catch (err) {
			return errorHandler(err);
		}
	} else {
		try {
			const res = await axios.patch(`${BASE_URL}revenue/update`, data, options);
			message.success('Repayment Updated');
			return res.data;
		} catch (err) {
			return errorHandler(err);
		}
	}
};

export const updateAdvance = async (data: { advanceId: string }) => {
	const options = {
		headers: {
			Authorization: `Bearer ${store.getState().auth.accessToken}`,
			'Content-Type': 'application/json',
		},
	};
	try {
		const res = await axios.patch(
			`${BASE_URL}advance/${data.advanceId}`,
			data,
			options
		);
		message.success('Advance Updated');
		return res.data;
	} catch (err) {
		return errorHandler(err);
	}
};

export const addForceState = async (clientId: string, state: string) => {
	const options = {
		headers: {
			Authorization: `Bearer ${store.getState().auth.accessToken}`,
			'Content-Type': 'application/json',
		},
	};
	try {
		const data = { clientId, state };
		await axios.post(`${BASE_URL}client/add-force-state`, data, options);
		message.success('Force State Added');
		return true;
	} catch (err) {
		return errorHandler(err);
	}
};

export const updateForceState = async (clientId: string, state: string) => {
	const options = {
		headers: {
			Authorization: `Bearer ${store.getState().auth.accessToken}`,
			'Content-Type': 'application/json',
		},
	};
	try {
		const data = { clientId, state };
		await axios.post(
			`${BASE_URL}client/remove-force-state/${clientId}`,
			data,
			options
		);
		message.success('Force State Deleted');
		return true;
	} catch (err) {
		return errorHandler(err);
	}
};

export const updateManualBankTransferStatus = async (data: {
	status: string;
	transactionId: string;
}) => {
	const options = {
		headers: {
			Authorization: `Bearer ${store.getState().auth.accessToken}`,
			'Content-Type': 'application/json',
		},
	};
	try {
		await axios.patch(`${BASE_URL}manual-transfer`, data, options);
		message.success('Manual transfer status updated');
	} catch (err) {
		return errorHandler(err);
	}
};

export const getRevenueSourceInfo = async (clientId: string) => {
	const options = {
		headers: {
			'Content-Type': 'application/json',
			Authorization: `Bearer ${store.getState().auth.accessToken}`,
		},
	};
	try {
		const data = { clientId };
		const result = await axios.post(
			`${BASE_URL}connect/revenue-sources`,
			data,
			options
		);

		return result.data;
	} catch (err) {
		return err;
	}
};

//Mandate list
export const mandateList = async (clientId: string) => {
	const options = {
		headers: {
			Authorization: `Bearer ${store.getState().auth.accessToken}`,
			'Content-Type': 'application/json',
		},
	};
	try {
		const res = await axios.get(
			`${BASE_URL}connect/mandate/${clientId}`,
			options
		);
		return res.data;
	} catch (err) {
		errorHandler(err);
		return err;
	}
};
export const mandateListById = async (id: string) => {
	const options = {
		headers: {
			Authorization: `Bearer ${store.getState().auth.accessToken}`,
			'Content-Type': 'application/json',
		},
	};
	try {
		const res = await axios.get(`${BASE_URL}connect/mandate/${id}`, options);
		return res.data;
	} catch (err) {
		return errorHandler(err);
	}
};
export const mandateUpdate = async (body: any) => {
	const options = {
		headers: {
			Authorization: `Bearer ${store.getState().auth.accessToken}`,
			'Content-Type': 'application/json',
		},
	};
	try {
		const res = await axios.patch(`${BASE_URL}connect/mandate`, body, options);
		message.success('Mandate Updated');
		return res.data;
	} catch (err) {
		return errorHandler(err);
	}
};

// Stripe list
export const stripeList = async (clientId: string) => {
	const options = {
		headers: {
			Authorization: `Bearer ${store.getState().auth.accessToken}`,
			'Content-Type': 'application/json',
		},
	};
	try {
		const res = await axios.get(`${BASE_URL}connect/stripe/${clientId}`, options);
		return res.data;
	} catch (err) {
		return errorHandler(err);
	}
};
export const stripeUpdate = async (body: any) => {
	const options = {
		headers: {
			Authorization: `Bearer ${store.getState().auth.accessToken}`,
			'Content-Type': 'application/json',
		},
	};
	try {
		const res = await axios.patch(`${BASE_URL}connect/stripe`, body, options);
		message.success('Update Successful');
		return res.data;
	} catch (err) {
		return errorHandler(err);
	}
};

// Shopify list
export const shopifyList = async (clientId: string) => {
	const options = {
		headers: {
			Authorization: `Bearer ${store.getState().auth.accessToken}`,
			'Content-Type': 'application/json',
		},
	};
	try {
		const res = await axios.get(
			`${BASE_URL}connect/shopify/${clientId}`,
			options
		);
		return res.data;
	} catch (err) {
		return errorHandler(err);
	}
};

export const shopifyUpdate = async (body: any) => {
	const options = {
		headers: {
			Authorization: `Bearer ${store.getState().auth.accessToken}`,
			'Content-Type': 'application/json',
		},
	};
	try {
		const res = await axios.patch(`${BASE_URL}connect/shopify`, body, options);
		message.success('Update Successful');
		return res.data;
	} catch (err) {
		return errorHandler(err);
	}
};

// Facebook list
export const facebookList = async (clientId: string) => {
	const options = {
		headers: {
			Authorization: `Bearer ${store.getState().auth.accessToken}`,
			'Content-Type': 'application/json',
		},
	};
	try {
		const res = await axios.get(
			`${BASE_URL}connect/facebook/${clientId}`,
			options
		);
		return res.data;
	} catch (err) {
		return errorHandler(err);
	}
};

export const facebookUpdate = async (body: any) => {
	const options = {
		headers: {
			Authorization: `Bearer ${store.getState().auth.accessToken}`,
			'Content-Type': 'application/json',
		},
	};
	try {
		const res = await axios.patch(`${BASE_URL}connect/facebook`, body, options);
		message.success('Update Successful');
		return res.data;
	} catch (err) {
		return errorHandler(err);
	}
};
