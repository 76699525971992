import React from 'react';
import { Table } from 'antd';
import {
	ArrowDownOutlined,
	ArrowUpOutlined,
	LineOutlined,
} from '@ant-design/icons';
import moment from 'moment-timezone';
moment.tz.setDefault('Europe/London');

interface HistoricalLogsCardProps {
	logsData: [
		{
			id: string;
			advanceId: string;
			createdAt: string;
			reason: string;
			snap: string;
		}
	];
}

interface logData {
	id: string;
	advanceId: string;
	createdAt: string;
	reason: string;
	snapA: any;
	snapB?: any;
}

const HistoricalLogsCard: React.FC<HistoricalLogsCardProps> = ({
	logsData,
}) => {
	// preprocess the data to create new rows by combining two consecutive rows
	const combinedData = [];
	for (let i = 0; i < logsData.length; i++) {
		const currentRow = logsData[i];
		const nextRow = logsData[i + 1];

		const combinedRow = {
			advanceId: currentRow.advanceId,
			createdAt: currentRow.createdAt,
			reason: currentRow.reason,
			snapA: JSON.parse(currentRow.snap),
			snapB: nextRow ? JSON.parse(nextRow.snap) : {},
		};
		combinedData.push(combinedRow);
	}

	const getIcon = (a: number, b: number) => {
		if (a > b) {
			return <ArrowUpOutlined style={{ color: '#15BE31' }} />;
		} else if (a < b) {
			return <ArrowDownOutlined style={{ color: '#EA2A05' }} />;
		} else {
			return <LineOutlined style={{ color: '#D9C705' }} />;
		}
	};

	const columns = [
		{
			title: 'Date',
			width: 120,
			dataIndex: 'createdAt',
			key: 'createdAt',
			fixed: 'left' as const,
			render: (date: string) => moment(date).format('lll'),
		},
		{
			title: 'Initial Balance',
			width: 120,
			key: '1',
			render: (row: logData) => {
				return (
					<>
						{String(row.snapA.initialBalance / 100).replace(
							/\B(?=(\d{3})+(?!\d))/g,
							','
						)}{' '}
						{getIcon(
							row.snapA.initialBalance,
							row.snapB.initialBalance
								? row.snapB.initialBalance
								: row.snapA.initialBalance
						)}
					</>
				);
			},
		},
		{
			title: 'Fee',
			width: 120,
			key: '2',
			render: (row: logData) => {
				return (
					<>
						{String(row.snapA.fee / 100).replace(/\B(?=(\d{3})+(?!\d))/g, ',')}{' '}
						{getIcon(row.snapA.fee, row.snapB.fee ? row.snapB.fee : row.snapA.fee)}
					</>
				);
			},
		},
		{
			title: 'Repayment Rate',
			width: 120,
			key: '3',
			render: (row: logData) => {
				return (
					<>
						{row.snapA.repaymentRate}{' '}
						{getIcon(
							parseFloat(row.snapA.repaymentRate),
							row.snapB.fee
								? parseFloat(row.snapB.repaymentRate)
								: parseFloat(row.snapA.repaymentRate)
						)}
					</>
				);
			},
		},
		{
			title: 'Total Repaid Projection',
			width: 120,
			key: '4',
			render: (row: logData) => {
				return (
					<>
						{String(row.snapA.totalRepaidProjection / 100).replace(
							/\B(?=(\d{3})+(?!\d))/g,
							','
						)}{' '}
						{getIcon(
							row.snapA.totalRepaidProjection,
							row.snapB.totalRepaidProjection
								? row.snapB.totalRepaidProjection
								: row.snapA.totalRepaidProjection
						)}
					</>
				);
			},
		},
		{
			title: 'Total Repaid',
			width: 120,
			key: '5',
			render: (row: logData) => {
				return (
					<>
						{String(row.snapA.totalRepaid / 100).replace(
							/\B(?=(\d{3})+(?!\d))/g,
							','
						)}{' '}
						{getIcon(
							row.snapA.totalRepaid,
							row.snapB.totalRepaid ? row.snapB.totalRepaid : row.snapA.totalRepaid
						)}
					</>
				);
			},
		},
		{
			title: 'Total Outstanding',
			width: 120,
			key: '6',
			render: (row: logData) => {
				return (
					<>
						{String(row.snapA.totalAmountOutstanding / 100).replace(
							/\B(?=(\d{3})+(?!\d))/g,
							','
						)}{' '}
						{getIcon(
							row.snapA.totalAmountOutstanding,
							row.snapB.totalAmountOutstanding
								? row.snapB.totalAmountOutstanding
								: row.snapA.totalAmountOutstanding
						)}
					</>
				);
			},
		},
		{
			title: 'Max Repayment',
			width: 120,
			key: '6',
			render: (row: logData) => {
				return (
					<>
						{String(row.snapA.maxRepayment / 100).replace(
							/\B(?=(\d{3})+(?!\d))/g,
							','
						)}{' '}
						{getIcon(
							row.snapA.maxRepayment,
							row.snapB.maxRepayment ? row.snapB.maxRepayment : row.snapA.maxRepayment
						)}
					</>
				);
			},
		},
		{
			title: 'Min Repayment',
			width: 120,
			key: '6',
			render: (row: logData) => {
				return (
					<>
						{String(row.snapA.minRepayment / 100).replace(
							/\B(?=(\d{3})+(?!\d))/g,
							','
						)}{' '}
						{getIcon(
							row.snapA.minRepayment,
							row.snapB.minRepayment ? row.snapB.minRepayment : row.snapA.minRepayment
						)}
					</>
				);
			},
		},
		{
			title: 'Reason',
			width: 120,
			key: '7',
			render: (row: logData) => row.reason,
		},
	];

	return (
		<>
			<Table
				columns={columns}
				pagination={false}
				dataSource={combinedData}
				bordered
				scroll={{ x: 800 }}
			/>
		</>
	);
};

export default HistoricalLogsCard;
