/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';
import { Table } from 'antd';

interface Props {
	columns: any;
	data: any;
	pagination: any;
	styleClass: any;
}

const BaseTable: React.FC<Props> = ({
	columns,
	data,
	pagination,
	styleClass,
}) => {
	return (
		<Table
			columns={columns}
			dataSource={data}
			pagination={{
				defaultPageSize: pagination,
				showSizeChanger: true,
			}}
			className={styleClass}
		/>
	);
};

export default BaseTable;
