import { Button, Form, Row, Select } from 'antd';
import { useForm } from 'antd/lib/form/Form';
import Modal from 'antd/lib/modal/Modal';
import { useRouter } from 'next/router';
import React from 'react';
import { connect } from 'react-redux';
import { submitSelectPlatForm } from 'services/oldservice';
import { getClient } from 'store/actions/dashboardActions';
import { ClientInfo } from '../ClientInfoProps/clientInfo';

interface Props {
	visible: boolean;
	onExitView: () => void;
	clientInfo: ClientInfo;
	getClient: (clientId: string) => void;
}

const DefaultSourceModal: React.FC<Props> = ({
	visible,
	onExitView,
	clientInfo,
	getClient,
}) => {
	const { Option } = Select;
	const [form] = useForm();
	const router = useRouter();
	const { id } = router.query;

	const onFinish = async (data: { platform: string }) => {
		const body = {
			...data,
			clientId: clientInfo.clientId,
		};
		await submitSelectPlatForm(body);
		getClient(id.toString());
		form.resetFields();
		onExitView();
	};

	return (
		<Modal
			title="Select the main source"
			visible={visible}
			onCancel={onExitView}
			footer={null}
		>
			<Row justify="center" align="middle">
				<Form
					onFinish={onFinish}
					layout="vertical"
					form={form}
					style={{ width: '80%' }}
				>
					<Form.Item
						label="Select The Source"
						name="platform"
						rules={[
							{
								required: true,
								message: 'Please confirm your Source',
							},
						]}
					>
						<Select
							defaultValue={
								clientInfo.businessPlatform.defaultSource
									? clientInfo.businessPlatform.defaultSource
									: ''
							}
						>
							<Option
								disabled={
									clientInfo.businessPlatform
										? !clientInfo.businessPlatform.STRIPE
										: false
								}
								value="STRIPE"
							>
								Stripe
							</Option>
							<Option
								disabled={
									clientInfo.businessPlatform
										? !clientInfo.businessPlatform.SHOPIFY
										: false
								}
								value="SHOPIFY"
							>
								Shopify
							</Option>
							<Option
								disabled={
									clientInfo.businessPlatform
										? !clientInfo.businessPlatform.WOOCOMMERCE
										: false
								}
								value="WOOCOMMERCE"
							>
								Woocommerce
							</Option>
						</Select>
					</Form.Item>
					<Button type="primary" htmlType="submit">
						Set
					</Button>
				</Form>
			</Row>
		</Modal>
	);
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const mapDispatchToProps = (dispatch: any) => ({
	getClient: (clientId: string) => {
		dispatch(getClient(clientId));
	},
});

export default connect(null, mapDispatchToProps)(DefaultSourceModal);
