/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useEffect } from 'react';
import Head from 'next/head';
import { useRouter } from 'next/router';
import { Layout } from 'antd';
import { SideNav, TopNav } from 'components';
import { connect } from 'react-redux';
import { RootState } from 'dto/redux.action.dto';
import { logOut } from 'store/actions/authActions';
import { clearDashboard } from 'store/actions/dashboardActions';
import { setDeploymentKey } from 'store/actions/settingsActions';
import { DEPLOYMENT_KEY } from 'config';
import dashStyles from 'design/scss/dashboardLayout.module.scss';

import * as Sentry from '@sentry/nextjs';

interface DashBoardLayoutProps {
	title: string;
	isLoggedIn: boolean;
	loggedInUser: string;
	children: any;
	deploymentKey: string | null;
	logOut: () => void;
	clearDashboard: () => void;
	setDeploymentKey: (deploymentKey: string) => void;
}

const DashBoardLayout: React.FC<DashBoardLayoutProps> = ({
	title,
	isLoggedIn,
	loggedInUser,
	children,
	deploymentKey,
	logOut,
	clearDashboard,
	setDeploymentKey,
}) => {
	const router = useRouter();

	const checkDeployment = () => {
		if (DEPLOYMENT_KEY !== deploymentKey) {
			clearDashboard();
			logOut();
			setDeploymentKey(DEPLOYMENT_KEY);
		}
	};

	useEffect(() => {
		checkDeployment();
		Sentry.setUser({ user: loggedInUser });
		if (!isLoggedIn) {
			router.push('/auth/login');
		}
	}, [isLoggedIn]);

	return (
		<React.Fragment>
			<Head>
				<title>Valerian | {title}</title>
			</Head>

			<main>
				<Layout className={dashStyles.dashLayout}>
					<Layout.Header className={dashStyles.dashLayout_headback}>
						<TopNav />
					</Layout.Header>
					<Layout>
						<div className={dashStyles.dashLayout_sidenaveContainer}>
							<SideNav />
						</div>
						<Layout.Content className={dashStyles.dashLayout_content}>
							{children}
						</Layout.Content>
					</Layout>
				</Layout>
			</main>
		</React.Fragment>
	);
};

const mapStateToProps = (state: RootState) => ({
	isLoggedIn: state.auth.isLoggedIn,
	loggedInUser: state.auth.loggedInUser?.email,
	deploymentKey: state.settings.deploymentKey,
});

const mapDispatchToProps = (dispatch: any) => ({
	setDeploymentKey: (deploymentKey: string) => {
		dispatch(setDeploymentKey(deploymentKey));
	},
	logOut: () => dispatch(logOut()),
	clearDashboard: () => dispatch(clearDashboard()),
});

export default connect(mapStateToProps, mapDispatchToProps)(DashBoardLayout);
