import React from 'react';
import { Row, Breadcrumb, Button } from 'antd';
import clientInfoStyles from 'design/scss/clientInfo.module.scss';
import { useRouter } from 'next/router';

interface Props {
	clientId: string;
}

const ClientBreadCrumCard: React.FC<Props> = ({ clientId }) => {
	const router = useRouter();

	return (
		<div className={clientInfoStyles.TopSection}>
			<Row justify={'space-between'} align={'middle'}>
				<Row align="middle">
					<span className={clientInfoStyles.TopSection_clientInfo}>Client/ </span>
					<span>
						<h3>{clientId}</h3>
					</span>
				</Row>
				<Breadcrumb className={clientInfoStyles.TopSection_breadcrums}>
					<Breadcrumb.Item>
						<p>{router.asPath}</p>
					</Breadcrumb.Item>
				</Breadcrumb>
				<Row className={clientInfoStyles.TopSection_buttonsConatiner}>
					<Button
						// className={clientInfoStyles.TopSection_specialButtonLeft}
						disabled
					>
						Flag Client
					</Button>
					<Button
						// className={clientInfoStyles.TopSection_specialButtonRight}
						disabled
					>
						Deactivate
					</Button>
				</Row>
			</Row>
		</div>
	);
};

export default ClientBreadCrumCard;
