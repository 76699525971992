/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useEffect } from 'react';
import Head from 'next/head';
import { useRouter } from 'next/router';
import { connect } from 'react-redux';
import { logOut } from 'store/actions/authActions';
import { clearDashboard } from 'store/actions/dashboardActions';
import { setDeploymentKey } from 'store/actions/settingsActions';
import { DEPLOYMENT_KEY } from 'config';
import authStyles from 'design/scss/auth.module.scss';

interface AuthLayoutProps {
	title: string;
	isLoggedIn: boolean;
	accessToken: string | undefined;
	children: JSX.Element;
	deploymentKey: string | null;
	logOut: () => void;
	clearDashboard: () => void;
	setDeploymentKey: (deploymentKey: string) => void;
}

const AuthLayout: React.FC<AuthLayoutProps> = ({
	title,
	isLoggedIn,
	accessToken,
	children,
	deploymentKey,
	logOut,
	clearDashboard,
	setDeploymentKey,
}) => {
	const router = useRouter();

	const checkDeployment = () => {
		if (DEPLOYMENT_KEY !== deploymentKey) {
			clearDashboard();
			logOut();
			setDeploymentKey(DEPLOYMENT_KEY);
		}
	};

	useEffect(() => {
		checkDeployment();
		if (isLoggedIn && accessToken) {
			router.push('/dashboard/clients');
		}
	}, [isLoggedIn, accessToken]);

	return (
		<React.Fragment>
			<Head>
				<title>Valerian | {title}</title>
			</Head>
			<main className={authStyles.authLayout}>{children}</main>
		</React.Fragment>
	);
};

const mapStateToProps = (state: {
	auth: { isLoggedIn: boolean; accessToken: string | undefined };
	settings: { deploymentKey: string };
}) => ({
	isLoggedIn: state.auth.isLoggedIn,
	accessToken: state.auth.accessToken,
	deploymentKey: state.settings.deploymentKey,
});

const mapDispatchToProps = (dispatch: any) => ({
	setDeploymentKey: (deploymentKey: string) => {
		dispatch(setDeploymentKey(deploymentKey));
	},
	logOut: () => dispatch(logOut()),
	clearDashboard: () => dispatch(clearDashboard()),
});

export default connect(mapStateToProps, mapDispatchToProps)(AuthLayout);
