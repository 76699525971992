import React, { useEffect, useState } from 'react';
import { Row, Table, Space, Pagination } from 'antd';
import { getDocType, getDocTypePage } from 'services/dashboard';
import kycStyles from '../../design/scss/kycCards.module.scss';
import { DocumentData, TableData } from 'dto/admin.client.dto';
import { ClientInfo } from 'components/ClientInfoProps/clientInfo';
import moment from 'moment-timezone';
moment.tz.setDefault('Europe/London');

interface Props {
	clientInfo: ClientInfo;
}

const KYCCard: React.FC<Props> = ({ clientInfo }) => {
	const [tableData, setTableData] = useState<TableData | null>(null);
	const [loading, setLoading] = useState<boolean>(false);

	const getFiles = async () => {
		setLoading(true);
		const res: { data: TableData } = await getDocType('KYC');
		setTableData(res.data);
		setLoading(false);
	};

	useEffect(() => {
		getFiles();
	}, []);

	const columns = [
		{
			title: 'Date',
			dataIndex: 'createdAt',
			key: 'createdAt',
			render: (value: string) => moment(value).format('YYYY-MM-DD HH:MM'),
		},
		{
			title: 'Title',
			dataIndex: 'fileType',
			key: 'fileType',
			render: (value: string, row: DocumentData) => {
				const str = row.url.split('--').pop();
				const str2 = str.replace(/%20/g, ' ');

				if (value === 'contractReupload') {
					return `Contract Signed Doc ${String(
						str2.substring(0, str2.indexOf('.'))
					).replace(/[^a-zA-Z0-9]/g, ' ')}`;
				} else if (value === 'businessInfo') {
					return `FINANCIALS ${String(str2.substring(0, str2.indexOf('.'))).replace(
						/[^a-zA-Z0-9]/g,
						' '
					)}`;
				} else {
					return `${value.toUpperCase()} ${String(
						str2.substring(0, str2.indexOf('.'))
					).replace(/[^a-zA-Z0-9]/g, ' ')}`;
				}
			},
		},
		//action
		{
			title: 'Action',
			dataIndex: 'url',
			key: 'action',
			render: (url: string) => (
				<Space size="middle">
					<a href={url}>{'Download >'}</a>
				</Space>
			),
		},
	];

	const pageOnChange = async (page: number) => {
		setLoading(true);

		getDocTypePage('KYC', page)
			.then((res: { data: TableData }) => {
				setTableData(res.data);
				setLoading(false);
			})
			.catch(() => {
				setTableData(null);
				setLoading(false);
			});
	};

	return (
		<>
			<Row className={kycStyles.infoContainer}>
				<Row justify="space-between" style={{ width: '100%', padding: '8px' }}>
					<span className={kycStyles.infoContainer_half_title}>First Name</span>
					<span className={kycStyles.infoContainer_half}>
						{clientInfo !== null && clientInfo.kyc ? clientInfo.kyc.firstName : 'NA'}
					</span>
				</Row>
				<Row justify="space-between" style={{ width: '100%', padding: '8px' }}>
					<span className={kycStyles.infoContainer_half_title}>Last Name</span>
					<span className={kycStyles.infoContainer_half}>
						{clientInfo !== null && clientInfo.kyc ? clientInfo.kyc.lastName : 'NA'}
					</span>
				</Row>
				<Row justify="space-between" style={{ width: '100%', padding: '8px' }}>
					<span className={kycStyles.infoContainer_half_title}>Role</span>
					<span className={kycStyles.infoContainer_half}>
						{clientInfo !== null && clientInfo.kyc
							? clientInfo.kyc.companyName
							: 'NA'}
					</span>
				</Row>
				<Row justify="end" style={{ width: '100%', padding: '8px' }}>
					<Table
						columns={columns}
						dataSource={tableData ? tableData.docs : []}
						className={kycStyles.infoContainer_table}
						pagination={false}
						loading={loading}
					/>
					<Pagination
						style={{ float: 'right' }}
						current={tableData?.page}
						total={tableData?.totalPages * 10}
						onChange={pageOnChange}
					/>
				</Row>
			</Row>
		</>
	);
};

export default KYCCard;
