/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { message } from 'antd';
import axios from 'axios';
import { BASE_URL } from 'config';
import { store } from 'store';

//Option for auth guard API
export const clientList = async () => {
	const options = {
		headers: {
			Authorization: `Bearer ${store.getState().auth.accessToken}`,
			'Content-Type': 'application/json',
		},
	};
	try {
		const result = await axios.get(`${BASE_URL}client/list?limit=10`, options);
		return result;
	} catch (err) {
		if (err?.response?.status == 403) {
			throw err;
		}
		throw err;
	}
};

export const createClientOffer = async (data: any) => {
	const options = {
		headers: {
			Authorization: `Bearer ${store.getState().auth.accessToken}`,
			'Content-Type': 'application/json',
		},
	};
	try {
		const result = await axios.post(`${BASE_URL}offer`, data, options);
		return result;
	} catch (err) {
		if (err?.response?.status == 403) {
			throw err;
		}
		throw err;
	}
};

// export const updateClientOffer = async (data: any) => {
// 	const options = {
// 		headers: {
// 			Authorization: `Bearer ${store.getState().auth.accessToken}`,
// 			'Content-Type': 'application/json',
// 		},
// 	};
// 	try {
// 		const result = await axios.patch(
// 			`${BASE_URL}offer/${data.offerId}`,
// 			data,
// 			options
// 		);
// 		return result;
// 	} catch (err) {
// 		if (err?.response?.status == 403) {
// 			throw err;
// 		}
// 		throw err;
// 	}
// };

export const updateClientOffer = async (data: any) => {
	const options = {
		headers: {
			Authorization: `Bearer ${store.getState().auth.accessToken}`,
			'Content-Type': 'application/json',
		},
	};
	try {
		const result = await axios.patch(
			`${BASE_URL}offer/${data.offerId}`,
			data,
			options
		);
		return result;
	} catch (err) {
		if (err?.response?.status == 403) {
			throw err;
		}
		throw err;
	}
};

export const visitorList = async () => {
	const options = {
		headers: {
			Authorization: `Bearer ${store.getState().auth.accessToken}`,
			'Content-Type': 'application/json',
		},
	};
	try {
		const result = await axios.get(`${BASE_URL}visitor?limit=10`, options);
		return result;
	} catch (err) {
		throw err.response.data;
	}
};

export const advanceList = async () => {
	const options = {
		headers: {
			Authorization: `Bearer ${store.getState().auth.accessToken}`,
			'Content-Type': 'application/json',
		},
	};
	try {
		const result = await axios.get(`${BASE_URL}advance`, options);
		return result;
	} catch (err) {
		throw err.response.data;
	}
};

export const getAdvanceById = async (advanceId: string) => {
	const options = {
		headers: {
			Authorization: `Bearer ${store.getState().auth.accessToken}`,
			'Content-Type': 'application/json',
		},
	};
	try {
		const result = await axios.get(`${BASE_URL}advance/${advanceId}`, options);
		return result;
	} catch (err) {
		throw err.response.data;
	}
};

export const updateAdvanceStatus = async (
	advanceId: string,
	status: string
) => {
	const options = {
		headers: {
			Authorization: `Bearer ${store.getState().auth.accessToken}`,
			'Content-Type': 'application/json',
		},
	};
	try {
		const result = await axios.patch(
			`${BASE_URL}advance/status/${advanceId}`,
			{
				status,
			},
			options
		);
		return result;
	} catch (err) {
		throw err.response.data;
	}
};

export const deleteRpayment = async (id: string, status: string) => {
	const options = {
		headers: {
			Authorization: `Bearer ${store.getState().auth.accessToken}`,
			'Content-Type': 'application/json',
		},
	};
	try {
		const data = {
			id: id,
			goCardlessReqStatus: status,
		};
		const result = await axios.patch(
			`${BASE_URL}jobs/clientDirectDebit`,
			data,
			options
		);
		return result;
	} catch (err) {
		throw err.response.data;
	}
};

export const updateAdvanceData = async (
	advanceId: string,
	data: { repaymentRate: number }
) => {
	const options = {
		headers: {
			Authorization: `Bearer ${store.getState().auth.accessToken}`,
			'Content-Type': 'application/json',
		},
	};
	try {
		const result = await axios.patch(
			`${BASE_URL}advance/${advanceId}`,
			data,
			options
		);
		message.success('Advance Updated');
		return result;
	} catch (err) {
		if (err?.response?.status === 403) {
			message.error('Advance is already approved');
			throw err.response.data;
		}
		message.error('Advance Could not be updated');
		throw err.response.data;
	}
};

export const updateAdvanceDataRevSources = async (
	advanceId: string,
	data: { advanceId: string; sources: any; repaymentRate: number }
) => {
	const options = {
		headers: {
			Authorization: `Bearer ${store.getState().auth.accessToken}`,
			'Content-Type': 'application/json',
		},
	};
	try {
		const result = await axios.post(
			`${BASE_URL}advance/update-rev-src/`,
			data,
			options
		);
		message.success('Advance Updated for sources');
		return result;
	} catch (err) {
		if (err?.response?.status === 403) {
			message.error('Advance is already approved');
			throw err.response.data;
		}
		message.error('Advance Could not be updated');
		throw err.response.data;
	}
};

export const getClientOffers = async (id: string) => {
	const options = {
		headers: {
			Authorization: `Bearer ${store.getState().auth.accessToken}`,
			'Content-Type': 'application/json',
		},
	};
	try {
		const result = await axios.get(`${BASE_URL}offer/${id}`, options);
		return result;
	} catch (err) {
		throw err.response.data;
	}
};
export const setDocumentSubmitted = async (
	status: boolean | null,
	clientId: string
) => {
	const options = {
		headers: {
			Authorization: `Bearer ${store.getState().auth.accessToken}`,
			'Content-Type': 'application/json',
		},
	};
	const data = {
		documentSubmitted: !status,
	};
	try {
		await axios.patch(`${BASE_URL}client/${clientId}`, data, options);
	} catch (err) {
		//console.log(err);
	}
};

export const calculateRev = async (reqBody: {
	clientId: string;
	advanceId: string;
	sources: any[];
	fromDate: string;
	toDate: string;
	note: string;
}) => {
	const options = {
		headers: {
			Authorization: `Bearer ${store.getState().auth.accessToken}`,
			'Content-Type': 'application/json',
		},
	};
	try {
		const { data } = await axios.post(
			`${BASE_URL}revenue/calculation`,
			reqBody,
			options
		);
		message.success(data.message, 3);
	} catch (err: any) {
		message.error(err.response.data.message);
	}
};
export const updateRepayment = async (data: { _id: string }) => {
	const options = {
		headers: {
			Authorization: `Bearer ${store.getState().auth.accessToken}`,
			'Content-Type': 'application/json',
		},
	};
	try {
		const body = {
			...data,
			// eslint-disable-next-line no-underscore-dangle
			id: data._id,
		};
		const result = await axios.patch(
			`${BASE_URL}jobs/clientDirectDebit`,
			body,
			options
		);
		return result;
	} catch (err) {
		throw err.response.data;
	}
};

export const reqGoCardLess = async (id: string) => {
	const options = {
		headers: {
			Authorization: `Bearer ${store.getState().auth.accessToken}`,
			'Content-Type': 'application/json',
		},
	};
	try {
		const body = {
			id: id,
		};

		const result = await axios.post(
			`${BASE_URL}revenue/send-gocardless`,
			body,
			options
		);
		message.success('Go CardLess Request Sent');
		return result;
	} catch (err) {
		message.error(err.response.data.message);
		return err.response.data;
	}
};

export const onClientPageChange = async (page: number) => {
	const options = {
		headers: {
			Authorization: `Bearer ${store.getState().auth.accessToken}`,
			'Content-Type': 'application/json',
		},
	};
	try {
		const res = await axios.get(
			`${BASE_URL}client/list?limit=10&page=${page}`,
			options
		);
		return res.data;
	} catch (err) {
		//console.log(err);
	}
};

export const onVisitorChange = async (page: number) => {
	const options = {
		headers: {
			Authorization: `Bearer ${store.getState().auth.accessToken}`,
			'Content-Type': 'application/json',
		},
	};
	try {
		const res = await axios.get(
			`${BASE_URL}visitor?limit=10&page=${page}`,
			options
		);
		return res.data;
	} catch (err) {
		//console.log(err);
	}
};

export const onRepaymentChange = async (page: number) => {
	const options = {
		headers: {
			Authorization: `Bearer ${store.getState().auth.accessToken}`,
			'Content-Type': 'application/json',
		},
	};
	try {
		const res = await axios.get(`${BASE_URL}jobs/dailyRev?page=${page}`, options);
		return res.data;
	} catch (err) {
		//console.log(err);
	}
};

export const allClientList = async () => {
	const options = {
		headers: {
			Authorization: `Bearer ${store.getState().auth.accessToken}`,
			'Content-Type': 'application/json',
		},
	};
	try {
		const result = await axios.get(`${BASE_URL}client/all`, options);
		return result;
	} catch (err) {
		if (err?.response?.status == 403) {
			throw err;
		}
		throw err;
	}
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const calManualRev = async (data: any) => {
	const options = {
		headers: {
			Authorization: `Bearer ${store.getState().auth.accessToken}`,
			'Content-Type': 'application/json',
		},
	};
	try {
		await axios.post(`${BASE_URL}revenue/manual`, data, options);
		message.success('Manual Repayment Added');
	} catch (err) {
		if (err.response.data.statusCode === 406) {
			message.error(err.response.data.message);
		} else {
			message.error('Manual Repayment can not be added');
			throw err;
		}
	}
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const calManualBankTransfer = async (data: any) => {
	const options = {
		headers: {
			Authorization: `Bearer ${store.getState().auth.accessToken}`,
			'Content-Type': 'application/json',
		},
	};
	try {
		await axios.post(`${BASE_URL}manual-transfer`, data, options);
		message.success('Manual Repayment Added');
	} catch (err) {
		message.error('Manual Repayment can not be added');
	}
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const getHistoricalData = async (data: any) => {
	const options = {
		headers: {
			Authorization: `Bearer ${store.getState().auth.accessToken}`,
			'Content-Type': 'application/json',
		},
	};
	try {
		const result = await axios.post(
			`${BASE_URL}revenue/historical-data`,
			data,
			options
		);
		return result;
	} catch (err) {
		if (err?.response?.status == 403) {
			throw err;
		}
		throw err;
	}
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const getDataExtratorData = async (data: any) => {
	// const options = {
	// 	headers: {
	// 		Authorization: `Bearer ${store.getState().auth.accessToken}`,
	// 		'Content-Type': 'application/json',
	// 	},
	// };
	try {
		const result = await axios.get(
			`${BASE_URL}historical/stripe-data`,
			data
			// options
		);
		return result;
	} catch (err) {
		if (err?.response?.status == 403) {
			throw err;
		}
		throw err;
	}
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const getshopifyData = async (data: any) => {
	try {
		const result = await axios.get(`${BASE_URL}historical/shopify-data`, data);
		return result;
	} catch (err) {
		if (err?.response?.status == 403) {
			throw err;
		}
		throw err;
	}
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const submitSelectPlatForm = async (data: any) => {
	const options = {
		headers: {
			Authorization: `Bearer ${store.getState().auth.accessToken}`,
			'Content-Type': 'application/json',
		},
	};
	try {
		const result = await axios.post(
			`${BASE_URL}client/default-platform`,
			data,
			options
		);
		message.success('Default Platform Selected');
		return result;
	} catch (err) {
		message.error('Could not select default platform');
		if (err?.response?.status == 403) {
			throw err;
		}
		throw err;
	}
};
