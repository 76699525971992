enum ClientControllerPermission {
	//   CreateClient = 'CreateClient',
	ReadClient = 'ReadClient',
	UpdateClient = 'UpdateClient',
	//   DeleteClient = 'DeleteClient',

	GetClientAllPlatformStatus = 'GetClientAllPlatformStatus',

	AddForceState = 'AddForceState',
	RemoveForceState = 'RemoveForceState',

	AllClientList = 'AllClientList',

	SetDefaultPlatform = 'SetDefaultPlatform',

	CreateClientNote = 'CreateClientNote',
	ReadClientNote = 'ReadClientNote',
	UpdateClientNote = 'UpdateClientNote',
	DeleteClientNote = 'DeleteClientNote',
}

export default ClientControllerPermission;
