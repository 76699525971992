import React, { useEffect, useState } from 'react';
import { Row, Table, Space, Pagination } from 'antd';
import DocumentStyle from '../../design/scss/documentsCards.module.scss';
import { getAllDocuments, onDocumentPageChange } from 'services/dashboard';
import moment from 'moment-timezone';
import { RootState } from 'dto/redux.action.dto';
import { connect } from 'react-redux';
import { Role } from 'dto/admin.client.dto';
import FileControllerPermission from 'dto/permissions/file.permission';
import { permissionText } from 'dto/permissions/permission-text';
import AdminControllerPermission from 'dto/permissions/admin.permission';
moment.tz.setDefault('Europe/London');

interface TableData {
	docs: DocumentData[];
	page: number;
	totalPages: number;
}
interface DocumentData {
	createdAt: string;
	fileType: string;
	url: string;
}
interface Props {
	role: Role;
	level: string;
}

const DocumentsCard: React.FC<Props> = ({ role, level }) => {
	const [tableData, setTableData] = useState<TableData | null>(null);
	const [loading, setLoading] = useState<boolean>(false);

	const columns = [
		{
			title: 'Date',
			dataIndex: 'createdAt',
			key: 'createdAt',
			render: (value: string) => moment(value).format('LL'),
		},
		{
			title: 'Title',
			render: (row: DocumentData) => {
				const str = row.url.split('--').pop();
				return decodeURI(str);
			},
		},
		//action
		{
			title: 'Action',
			dataIndex: 'url',
			key: 'action',
			render: (url: string) => (
				<Space size="middle">
					<a href={url}>{'Download>'}</a>
				</Space>
			),
		},
	];

	useEffect(() => {
		getFiles();
	}, []);

	const getFiles = async () => {
		setLoading(true);
		const res = await getAllDocuments();
		setTableData(res.data);
		setLoading(false);
	};

	const data = [];
	for (let i = 0; i < 100; i++) {
		data.push({
			key: i,
			date: `March 12 2021 ${i}`,
			title: `Financials March 2021 ${i}`,
		});
	}
	const pageOnChange = async (page: number) => {
		setLoading(true);

		onDocumentPageChange(page)
			.then((data: { data: TableData }) => {
				setTableData(data.data);
				setLoading(false);
			})
			.catch(() => {
				setTableData(null);
				setLoading(false);
			});
	};

	return (
		<>
			<div>
				<Row className={DocumentStyle.infoContainer} justify="end">
					{role?.permissions?.includes(FileControllerPermission.ClientFileList) ||
					level === AdminControllerPermission.SUPER_ADMIN ? (
						<>
							<Table
								columns={columns}
								dataSource={tableData !== null ? tableData.docs : []}
								className={DocumentStyle.infoContainer_table}
								pagination={false}
								loading={loading}
							/>
							<Pagination
								current={tableData?.page}
								total={tableData?.totalPages * 10}
								onChange={pageOnChange}
							/>
						</>
					) : (
						<h3>{permissionText}</h3>
					)}
				</Row>
			</div>
		</>
	);
};
const mapStateToProps = (state: RootState) => ({
	role: state.auth?.loggedInUser?.role,
	level: state.auth.loggedInUser?.level,
});

export default connect(mapStateToProps)(DocumentsCard);
