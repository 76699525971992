import { Button, Col, Form, Input, message, Row } from 'antd';
import Link from 'next/link';
import React from 'react';
import { connect } from 'react-redux';
import { handleLogin } from '../../store/actions/authActions';

interface Props {
	handleLogin: (email: string, password: string) => void;
}

const LoginForm: React.FC<Props> = ({ handleLogin }) => {
	const onFinish = async (value: { email: string; password: string }) => {
		const { email, password } = value;
		handleLogin(email, password);
	};

	const onFinishFailed = () => {
		message.error('Login-attempt-failed');
	};

	return (
		<div>
			<h3>Log in</h3>

			<Form
				layout="vertical"
				onFinish={onFinish}
				onFinishFailed={onFinishFailed}
				style={{
					textAlign: 'start',
					display: 'flex',
					flexDirection: 'column',
					justifyContent: 'start',
				}}
				requiredMark={false}
			>
				<Form.Item
					label="E-mail"
					name="email"
					rules={[
						{
							required: true,
							message: 'Please enter your e-mail address',
						},
					]}
					style={{ width: '100%' }}
				>
					<Input type="email" />
				</Form.Item>

				<Form.Item
					label="Password"
					name="password"
					rules={[
						{
							required: true,
							message: 'Please enter your password',
						},
					]}
					style={{ width: '100%' }}
				>
					<Input.Password />
				</Form.Item>

				<Form.Item style={{ textAlign: 'start' }}>
					Don't have an account? <Link href="/auth/registration">Sign Up Here!</Link>
					<br />
					<Link href="/auth/forgotpassword">Forgot your password?</Link>
				</Form.Item>

				<Form.Item>
					<Row justify="center">
						<Col>
							<Button className="_form_submit_small" type="primary" htmlType="submit">
								Log in
							</Button>
						</Col>
					</Row>
				</Form.Item>
			</Form>
		</div>
	);
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const mapDispatchToProps = (dispatch: any) => ({
	handleLogin: (email: string, password: string) => {
		dispatch(handleLogin(email, password));
	},
});

export default connect(null, mapDispatchToProps)(LoginForm);
