enum AdvanceControllerPermission {
	CreateAdvance = 'CreateAdvance',
	ReadAdvance = 'ReadAdvance',
	UpdateAdvance = 'UpdateAdvance',
	DeleteAdvance = 'DeleteAdvance',

	AllAdvanceList = 'AllAdvanceList',

	ClientAllAdvanceList = 'ClientAllAdvanceList',
	ClientActiveAdvanceList = 'ClientActiveAdvanceList',

	ClientAdvanceRevSrc = 'ClientAdvanceRevSrc',

	UpdateAdvanceStatus = 'UpdateAdvanceStatus',
}

export default AdvanceControllerPermission;
